import { Link } from "react-router-dom";
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { CompanySidebarProps } from "../../interfaces/props";
import { PaperContainer } from "../PaperContainer";
import { formatPhoneNumber } from "../../helpers/phoneNumber";
import { UserPermissionSet } from "../../interfaces";
import { Roles, userHasRole } from "../../helpers/auth";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

export const CompanySidebar = (props: CompanySidebarProps) => {
    const { company, user, buttonActions } = props;

    const canEdit = (user: UserPermissionSet | null | undefined): boolean => {
        if (user) {
            return userHasRole(user, [Roles.admin, Roles.systemAdmin]);
        } else {
            return false;
        }
    };

    return (
        <Paper>
            <PaperContainer>
                <div className="text-center">
                    <h2>
                        {company?.name ?? "Company"}{" "}
                        {canEdit(user) && (
                            <IconButton
                                component={Link}
                                to={`/companies/${company?.id}`}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </h2>
                    <p>{company?.id}</p>

                    <Divider variant="middle" />

                    <p>
                        <b>Status: {company?.status ?? ""}</b>
                    </p>
                    <p>{company?.website ?? ""}</p>
                    <p>
                        <b>Office</b>
                        <br></br>
                        {formatPhoneNumber(company?.phoneNumber ?? "")}
                        {company?.phoneExtension
                            ? " x" + company?.phoneExtension
                            : ""}
                    </p>
                    {company?.mobilePhoneNumber &&
                        company?.mobilePhoneNumber.length > 0 && (
                            <p>
                                <b>Mobile</b>
                                <br></br>
                                {formatPhoneNumber(
                                    company?.mobilePhoneNumber ?? "",
                                )}
                            </p>
                        )}
                    <p className="show-newlines">{company?.hqAddress}</p>
                    <p>
                        Site Locations:{" "}
                        {company && (
                            <Switch
                                disabled={true}
                                checked={company?.hasSiteLocations}
                            />
                        )}
                    </p>

                    <Divider variant="middle" />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() =>
                                    buttonActions("activeContingent")
                                }
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="Active Contingent"
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => buttonActions("activeJobs")}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="Active Jobs"
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => buttonActions("closedJobs")}
                            >
                                <ListItemText
                                    style={{ textAlign: "center" }}
                                    primary="Closed Jobs"
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </div>
            </PaperContainer>
        </Paper>
    );
};
