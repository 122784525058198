import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    container: {
        color: "#A1A5A9",
        fontSize: 12,
        flexDirection: "row",
        borderBottomColor: "#E3E4E5",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        textTransform: "uppercase",
        fontStyle: "bold",
        flexGrow: 1,
    },
    service: {
        width: "15%",
    },
    description: {
        width: "52%",
    },
    qty: {
        width: "7%",
    },
    rate: {
        width: "13%",
    },
    amount: {
        width: "13%",
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.service}>Service</Text>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.qty}>Qty</Text>
        <Text style={styles.rate}>Rate</Text>
        <Text style={styles.amount}>Amount</Text>
    </View>
);

export default InvoiceTableHeader;
