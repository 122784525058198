import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { FormSelectFieldProps } from "../../interfaces/props";
import { ReferenceField } from "../../interfaces";

export const FormSelectField = (props: FormSelectFieldProps) => {
    return (
        <div className="form-field">
            {props.options && props.options.length > 0 && (
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        {props.label}
                    </InputLabel>
                    <Select {...props}>
                        {props.options.map((o: ReferenceField, i: number) => {
                            return (
                                <MenuItem
                                    key={`${props.id}-${i}`}
                                    value={o.value as string | number}
                                >
                                    {o.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            )}
        </div>
    );
};
