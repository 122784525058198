import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import DataTable, { TableColumn } from "react-data-table-component";
import { DatetimeFormat, DocumentRequest } from "../interfaces";
import { DocumentBase } from "../interfaces";
import { DocumentListProps } from "../interfaces/props";
import { FormTextField } from "./form/FormTextField";

export const DocumentList = (props: DocumentListProps) => {
    const [fileRef, setFileRef] = useState<number>(Date.now());
    const [loading, setLoading] = useState<boolean>(false);
    const {
        company,
        deleteDocument,
        documents,
        getDocument,
        getDocuments,
        saveDocument,
    } = props;
    const columns: TableColumn<DocumentBase>[] = [
        {
            name: "Name",
            selector: (row: DocumentBase) => row.name,
            cell: (row: DocumentBase) => (
                <a
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        getDocument(row);
                    }}
                >
                    {row.name}
                </a>
            ),
        },
        {
            name: "Description",
            selector: (row: DocumentBase) => row.description,
        },
        {
            name: "Created",
            selector: (row: DocumentBase) =>
                dayjs(row.created).format(DatetimeFormat),
        },
        {
            name: "",
            selector: () => "Delete",
            width: "100px",
            cell: (row: DocumentBase) => (
                <a
                    href=""
                    onClick={(e) => {
                        e.preventDefault();
                        deleteDocument(row);
                    }}
                >
                    Delete
                </a>
            ),
        },
    ];

    useEffect(() => {
        getDocuments();
    }, []);

    const validationSchema = yup.object({
        company: yup.string().required("Company is required"),
        description: yup.string().required("Description is required"),
        file: yup.mixed().required("File is required"),
    });

    const formik = useFormik({
        initialValues: {
            company: company,
            description: "",
            file: undefined as unknown as File,
        },
        validationSchema: validationSchema,
        onSubmit: async (value: DocumentRequest, { resetForm }) => {
            setLoading(true);
            await saveDocument(value);
            resetForm();
            setFileRef(Date.now());
            setLoading(false);
        },
    });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h1>Documents</h1>
            <form onSubmit={formik.handleSubmit} className="file-upload-form">
                <>
                    {formik.touched.file && formik.errors.file && (
                        <Stack sx={{ width: "100%" }} spacing={2}>
                            <Alert severity="error">
                                {formik.errors.file?.toString()}
                            </Alert>
                        </Stack>
                    )}

                    <FormTextField
                        key={fileRef}
                        fullWidth
                        id="file"
                        name="file"
                        type="file"
                        value={undefined}
                        onChange={(event) => {
                            const files = (event.target as HTMLInputElement)
                                .files;
                            const file =
                                files && files.length == 1
                                    ? files[0]
                                    : undefined;
                            formik.setFieldValue("file", file);
                        }}
                        error={
                            formik.touched.file && Boolean(formik.errors.file)
                        }
                        onBlur={formik.handleBlur}
                        disabled={loading}
                    />
                    <FormTextField
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                            formik.touched.description &&
                            Boolean(formik.errors.description)
                        }
                        helperText={
                            formik.touched.description &&
                            formik.errors.description
                        }
                        disabled={loading}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={loading}
                    >
                        Upload Document
                    </Button>
                </>
            </form>
            <DataTable
                data={documents ?? []}
                columns={columns}
                progressPending={loading}
                pagination
            />
        </Container>
    );
};
