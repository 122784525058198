import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import { CompanySiteLocationForm } from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const SiteLocationAddPage = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const saveSiteLocation = async (
        newSiteLocation: CompanySiteLocationForm,
    ) => {
        if (!appUser) {
            setLoading(false);
            return;
        }
        try {
            const api = await getApi();
            const response = await api.post(
                `/company/${companyId}/site-location`,
                newSiteLocation,
            );
            if (response.status === 201) {
                navigate(`/companies/${companyId}/profile`);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        address: yup.string().required("Address is required"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            address: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: CompanySiteLocationForm) => {
            setLoading(true);
            saveSiteLocation(values);
        },
    });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Add Site Location</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <FormTextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="address"
                                name="address"
                                label="Address"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                }
                                helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                }
                                disabled={loading}
                                multiline
                                rows={5}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Add Site Location
                            </Button>
                        </>
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
