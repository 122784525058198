import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import { CommissionType } from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const CommissionTypePage = () => {
    const { commissionTypeId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [compCode, setCommissionType] = useState<CommissionType | null>(null);

    const navigate = useNavigate();

    const saveCommissionType = async (newCommissionType: CommissionType) => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.put(
                `/accounting/commission-type/${newCommissionType.id}`,
                newCommissionType,
            );
            setCommissionType(response.data as CommissionType);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
        navigate("/backoffice");
    };

    const validationSchema = yup.object({
        id: yup.string().required("ID is required"),
        name: yup.string().required("Name is required"),
        percentage: yup.number().required("Percentage is required"),
    });

    const formik = useFormik({
        initialValues: {
            id: commissionTypeId ?? "",
            name: "",
            percentage: 0.0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: CommissionType, { resetForm }) => {
            await saveCommissionType(values);
            resetForm();
        },
    });

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get(
                `/accounting/commission-type/${commissionTypeId}`,
            );
            const commissionTypeResponse = response.data as CommissionType;
            setCommissionType(commissionTypeResponse);
            formik.setValues(commissionTypeResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Edit Worker Comp Code</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {compCode && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="percentage"
                                    name="percentage"
                                    label="Percentage"
                                    type="number"
                                    value={formik.values.percentage}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.percentage &&
                                        Boolean(formik.errors.percentage)
                                    }
                                    helperText={
                                        formik.touched.percentage &&
                                        formik.errors.percentage
                                    }
                                    disabled={loading}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
