import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import {
    DateFormat,
    JobFilter,
    JobResponse,
    JobSearchForm,
} from "../interfaces";
import { Button, Chip } from "@mui/material";
import { Roles, userHasRole } from "../helpers/auth";
import { JobsListProps } from "../interfaces/props";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const JobsList = (props: JobsListProps) => {
    const { companyId, filters, setFilters } = props;
    const { getApi, user: appUser } = useContext(AppContext);
    const [jobs, setJobs] = useState<JobResponse[]>([] as JobResponse[]);

    const getLink = (row: JobResponse) => {
        return `/companies/${companyId}/job/${row.id}`;
    };

    const columns: TableColumn<JobResponse>[] = [
        {
            name: "Title",
            selector: (row: JobResponse) => row.title,
            cell: (row: JobResponse) => (
                <Link to={getLink(row)}>{row.title}</Link>
            ),
        },
        {
            name: "Status",
            selector: (row: JobResponse) => row.status,
        },
        {
            name: "Type",
            selector: (row: JobResponse) => row.type,
        },
        {
            name: "Start date",
            selector: (row: JobResponse) =>
                dayjs(row.startDate).format(DateFormat),
        },
        {
            name: "End date",
            selector: (row: JobResponse) =>
                row.endDate
                    ? dayjs(row.endDate).format(DateFormat)
                    : row.perpetual
                    ? "Perpetual"
                    : "",
        },
        {
            name: "Pay rate",
            selector: (row: JobResponse) =>
                row.payRate ? `$${row.payRate.toFixed(2)}` : "N/A",
        },
        {
            name: "Site location",
            selector: (row: JobResponse) => row.siteLocation?.name,
        },
    ];

    useEffect(() => {
        const searchData = async () => {
            const searchReq: JobSearchForm = {
                companyId: companyId as string,
            };
            filters?.reduce(
                (acc, el) => {
                    searchReq[el.field as keyof JobSearchForm] = el.value;
                    return acc;
                },
                [searchReq],
            );
            try {
                const api = await getApi();
                const response = await api.post("/job/search", searchReq);
                setJobs(response.data as JobResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        searchData();
    }, [companyId, filters]);

    const handleFilterDelete = (index: number) => {
        const newFilters = [...(filters as JobFilter[])];
        newFilters.splice(index, 1);
        if (setFilters) setFilters(newFilters as JobFilter[]);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h1>Jobs</h1>
            <p>
                {appUser &&
                    userHasRole(appUser, [Roles.admin, Roles.systemAdmin]) && (
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={`/companies/${companyId}/jobs/add`}
                        >
                            Add Job
                        </Button>
                    )}
            </p>
            {filters && filters.length > 0 && (
                <div>
                    {filters.map((filter, i) => {
                        return (
                            <Chip
                                key={`job-filter-${i}`}
                                label={`${filter.field} = ${filter.value}`}
                                onDelete={() => handleFilterDelete(i)}
                            />
                        );
                    })}
                </div>
            )}
            <DataTable data={jobs} columns={columns} pagination />
        </Container>
    );
};
