import { useContext, useEffect, useState } from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import {
    CompanyResponse,
    DocumentBase,
    DocumentRequest,
    JobFilter,
} from "../interfaces";
import { AppContext } from "../context/AppContext";
import { useParams } from "react-router-dom";
import { CompanySidebar } from "../components/profile/CompanySidebar";
import { PaperContainer } from "../components/PaperContainer";
import { TabPanel } from "../components/TabPanel";
import { ContactsList } from "../components/ContactsList";
import { SiteLocationsList } from "../components/SiteLocationsList";
import { JobsList } from "../components/JobsList";
import { JobStatus, JobType } from "../helpers/job";
import { NotesList } from "../components/NotesList";
import { DocumentList } from "../components/DocumentList";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";
import { CompanyBillingList } from "../components/CompanyBillingList";

export const CompanyProfile = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [company, setCompany] = useState<CompanyResponse | null>(null);
    const [tabValue, setTabValue] = useState<number>(0);
    const [jobFilters, setJobFilters] = useState<JobFilter[]>([]);
    const [documents, setDocuments] = useState<DocumentBase[] | null>(null);

    const getDocuments = async () => {
        try {
            const api = await getApi();
            const response = await api.get(`/document/company/${companyId}`);
            setDocuments(response.data as DocumentBase[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const saveDocument = async (request: DocumentRequest) => {
        const form = new FormData();
        form.append("company", request.company);
        form.append("description", request.description);
        form.append("file", request.file, request.file.name);

        try {
            const api = await getApi();
            await api.post("/document/company", form);
            getDocuments();
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const getDocument = async (doc: DocumentBase) => {
        try {
            const api = await getApi();
            const response = await api.get(
                `/document/company/${companyId}/document/${doc.name}`,
                { responseType: "blob" },
            );

            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", doc.name); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const deleteDocument = async (doc: DocumentBase) => {
        try {
            const api = await getApi();
            await api.delete(
                `/document/company/${companyId}/document/${doc.name}`,
            );
            getDocuments();
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/company/${companyId}`);
                setCompany(response.data as CompanyResponse);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchCompany();
    }, []);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleSidebarAction = (action: string) => {
        switch (action) {
            case "activeContingent":
                setJobFilters([
                    {
                        field: "type",
                        value: [JobType.contract, JobType.contract_to_hire],
                    },
                    {
                        field: "status",
                        value: JobStatus.open,
                    },
                ]);
                setTabValue(2);
                break;
            case "activeJobs":
                setJobFilters([
                    {
                        field: "status",
                        value: JobStatus.open,
                    },
                ]);
                setTabValue(2);
                break;
            case "closedJobs":
                setJobFilters([
                    {
                        field: "status",
                        value: JobStatus.close,
                    },
                ]);
                setTabValue(2);
                break;
            default:
                setTabValue(2);
        }
    };

    return (
        <>
            <Grid className="profile-grid" container spacing={2}>
                <Grid item md={3} xs={12}>
                    <CompanySidebar
                        company={company}
                        user={appUser}
                        buttonActions={handleSidebarAction}
                    />
                </Grid>
                <Grid item md={9} xs={12}>
                    <Paper>
                        <PaperContainer>
                            <Box>
                                <Tabs
                                    onChange={handleTabChange}
                                    value={tabValue}
                                    variant="scrollable"
                                >
                                    <Tab
                                        label="Contacts"
                                        aria-controls="company-tab-0"
                                    />
                                    <Tab
                                        label="Locations"
                                        aria-controls="company-tab-1"
                                    />
                                    <Tab
                                        label="Jobs"
                                        aria-controls="company-tab-2"
                                    />
                                    <Tab
                                        label="Billing"
                                        aria-controls="company-tab-3"
                                    />
                                    <Tab
                                        label="Documents"
                                        aria-controls="company-tab-4"
                                    />
                                    <Tab
                                        label="Rules"
                                        aria-controls="company-tab-5"
                                    />
                                    <Tab
                                        label="Notes"
                                        aria-controls="company-tab-6"
                                    />
                                </Tabs>
                            </Box>
                        </PaperContainer>
                        <PaperContainer>
                            <TabPanel
                                index={0}
                                value={tabValue}
                                aria="company-tab"
                            >
                                <ContactsList
                                    company={company}
                                    user={appUser}
                                />
                            </TabPanel>
                            <TabPanel
                                index={1}
                                value={tabValue}
                                aria="company-tab"
                            >
                                <SiteLocationsList
                                    company={company}
                                    user={appUser}
                                />
                            </TabPanel>
                            <TabPanel
                                index={2}
                                value={tabValue}
                                aria="company-tab"
                            >
                                <JobsList
                                    companyId={company?.id ?? null}
                                    filters={jobFilters}
                                    setFilters={setJobFilters}
                                />
                            </TabPanel>
                            <TabPanel
                                index={3}
                                value={tabValue}
                                aria="company-tab"
                            >
                                {company && (
                                    <CompanyBillingList company={company} />
                                )}
                            </TabPanel>
                            <TabPanel
                                index={4}
                                value={tabValue}
                                aria="company-tab"
                            >
                                {companyId && (
                                    <DocumentList
                                        company={companyId}
                                        deleteDocument={deleteDocument}
                                        documents={documents}
                                        getDocument={getDocument}
                                        getDocuments={getDocuments}
                                        saveDocument={saveDocument}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel
                                index={5}
                                value={tabValue}
                                aria="company-tab"
                            >
                                Rules
                            </TabPanel>
                            <TabPanel
                                index={6}
                                value={tabValue}
                                aria="company-tab"
                            >
                                <NotesList filter={{ companyId: companyId }} />
                            </TabPanel>
                        </PaperContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
