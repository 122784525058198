import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
    AccountingPeriod,
    DateRange,
    ReferenceField,
    TimeTrackingPeriod,
} from "../interfaces";

dayjs.extend(utc);

export const getFirstDayOfPeriod = (
    currentDate: dayjs.Dayjs,
    accountingPeriod: AccountingPeriod,
): dayjs.Dayjs => {
    const targetDate = accountingPeriod === "Monday to Sunday" ? 1 : 0;
    const cloneDate = currentDate.clone();
    const currentDay = cloneDate.day();
    if (currentDay === targetDate) {
        return cloneDate;
    } else if (targetDate === 1 && currentDay === 0) {
        return cloneDate.subtract(6, "day");
    } else {
        return cloneDate.subtract(currentDay - targetDate, "day");
    }
};

export const getLastDayOfPeriod = (firstDay: dayjs.Dayjs): dayjs.Dayjs => {
    const clonedDate = firstDay.clone();
    return clonedDate.add(6, "day");
};

export const formatTimeTrackingDate = (date: dayjs.Dayjs): string => {
    return date.format("YYYY-MM-DD");
};

export const timeTrackingPeriodToDateRange = (
    period: TimeTrackingPeriod,
): string => {
    return JSON.stringify({
        start: period.startDate,
        end: period.endDate,
    });
};

export const excludeDuplicateRanges = (
    ranges: DateRange[],
    originals: Set<string>,
): DateRange[] => {
    return ranges.filter((range) => !originals.has(JSON.stringify(range)));
};

export const getDescriptionFromDateRange = (range: DateRange) => {
    const dateStart = dayjs(range.start);
    const dateEnd = dayjs(range.end);
    const monthStart = dateStart.format("MMM");
    const monthEnd = dateEnd.format("MMM");
    const dayStart = dateStart.format("D");
    const dayEnd = dateEnd.format("D");
    const yearStart = dateStart.format("YYYY");
    const yearEnd = dateEnd.format("YYYY");

    return `${monthStart} ${dayStart}, ${yearStart} - ${monthEnd} ${dayEnd}, ${yearEnd}`;
};

export const getTimeTrackingPeriodDateRanges = (
    today: dayjs.Dayjs,
    accountingPeriod: AccountingPeriod,
    periods: TimeTrackingPeriod[],
): DateRange[] => {
    const firstDay = getFirstDayOfPeriod(today, accountingPeriod);
    const lastDay = getLastDayOfPeriod(firstDay);
    const currentRange: DateRange = {
        start: formatTimeTrackingDate(firstDay),
        end: formatTimeTrackingDate(lastDay),
    };

    const dateRanges: DateRange[] = [currentRange];
    let prevRange = [firstDay, lastDay];
    for (let i = 0; i < 51; i++) {
        const [prevFirst, prevLast] = prevRange;
        const nextFirst = prevFirst.clone().subtract(7, "day");
        const nextLast = prevLast.clone().subtract(7, "day");
        const nextRange: DateRange = {
            start: formatTimeTrackingDate(nextFirst),
            end: formatTimeTrackingDate(nextLast),
        };
        dateRanges.push(nextRange);
        prevRange = [nextFirst, nextLast];
    }

    const periodRanges = new Set<string>(
        periods.map(timeTrackingPeriodToDateRange),
    );
    const trimmedRanges = excludeDuplicateRanges(dateRanges, periodRanges);
    return trimmedRanges;
};

export const getTimeTrackingChoices = (
    today: dayjs.Dayjs,
    accountingPeriod: AccountingPeriod,
    periods: TimeTrackingPeriod[],
): ReferenceField[] => {
    const ranges = getTimeTrackingPeriodDateRanges(
        today,
        accountingPeriod,
        periods,
    );

    return ranges.map((range) => ({
        label: getDescriptionFromDateRange(range),
        value: JSON.stringify(range),
    }));
};
