import { PlacementsList } from "../components/PlacementsList";

export const PlacementsPage = () => {
    return (
        <>
            <PlacementsList active={true} />
            <PlacementsList active={false} />
        </>
    );
};
