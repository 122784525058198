import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import {
    AccountingSettings,
    DateRange,
    TimeTrackingPeriod,
    TimeTrackingPeriodBase,
} from "../interfaces";
import { TimeTrackingPeriodForm } from "../interfaces/props";
import { FormSelectField } from "../components/form/FormSelectField";
import {
    getDescriptionFromDateRange,
    getTimeTrackingChoices,
} from "../helpers/timeTracking";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const TimeTrackingListPage = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [periods, setPeriods] = useState<TimeTrackingPeriod[] | null>(null);
    const [accounting, setAccounting] = useState<AccountingSettings | null>(
        null,
    );

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get("/time-tracking");
            setPeriods(response.data);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const fetchAccountingSettings = async () => {
        const api = await getApi();
        try {
            const response = await api.get("/accounting/settings");
            const settings = response.data as AccountingSettings;
            setAccounting(settings);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        fetchData();
        fetchAccountingSettings();
    }, []);

    const getLink = (row: TimeTrackingPeriod): string => {
        return `/backoffice/time-tracking/${row.id}`;
    };

    const columns: TableColumn<TimeTrackingPeriod>[] = [
        {
            name: "Description",
            selector: (row: TimeTrackingPeriod) => row.description,
            cell: (row: TimeTrackingPeriod) => (
                <Link to={getLink(row)}>{row.description}</Link>
            ),
        },
        {
            name: "Start Date",
            selector: (row: TimeTrackingPeriod) => row.startDate,
        },
        {
            name: "End Date",
            selector: (row: TimeTrackingPeriod) => row.endDate,
        },
    ];

    const saveTimeTrackingPeriod = async (values: TimeTrackingPeriodForm) => {
        setLoading(true);
        const range: DateRange = JSON.parse(values.timeTrackingPeriod);
        const body: TimeTrackingPeriodBase = {
            startDate: range.start,
            endDate: range.end,
            description: getDescriptionFromDateRange(range),
        };
        const api = await getApi();
        try {
            await api.post("/time-tracking", body);
            await fetchData();
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        timeTrackingPeriod: yup
            .object({ start: yup.string(), end: yup.string() })
            .required("Time tracking period is required"),
    });

    const formik = useFormik({
        initialValues: {
            timeTrackingPeriod: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values: TimeTrackingPeriodForm, { resetForm }) => {
            await saveTimeTrackingPeriod(values);
            resetForm();
        },
    });

    const timeTrackingPeriodChoices =
        periods && accounting
            ? getTimeTrackingChoices(
                  dayjs(),
                  accounting?.accountingPeriod,
                  periods,
              )
            : [];

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Time Tracking</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <FormSelectField
                            fullWidth
                            id="timeTrackingPeriod"
                            name="timeTrackingPeriod"
                            label="Time tracking period"
                            value={formik.values.timeTrackingPeriod}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.timeTrackingPeriod &&
                                Boolean(formik.errors.timeTrackingPeriod)
                            }
                            options={timeTrackingPeriodChoices}
                            disabled={loading}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={
                                loading ||
                                !(formik.values.timeTrackingPeriod.length > 0)
                            }
                        >
                            Add Time Tracking Period
                        </Button>
                    </form>
                    <DataTable
                        data={periods ?? []}
                        columns={columns}
                        progressPending={loading}
                    />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
