import { AxiosError } from "axios";
import { ApiError } from "../interfaces";

export const handleError = (e: AxiosError) => {
    // log error
    console.error(e);

    if (e?.response?.status === 401) {
        // check for 401 auth error
        // send user to home page
        window.location.href = "/";
    }

    if ((e?.response?.data as ApiError)?.message) {
        alert((e?.response?.data as ApiError)?.message);
    } else {
        alert("There was an error processing this request");
    }
};
