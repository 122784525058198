import React from "react";
import { Route, Routes, Link, useLocation, useParams } from "react-router-dom";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { TimeTrackingHoursList } from "../components/TimeTrackingHoursList";
import { PaperContainer } from "../components/PaperContainer";
import { UserCommissionList } from "../components/UserCommissionList";
import { InvoiceList } from "../components/InvoiceList";

export const TimeTrackingPeriodPage = () => {
    const { timeTrackingPeriodId } = useParams();
    const { pathname } = useLocation();
    const baseUrl = `/backoffice/time-tracking/${timeTrackingPeriodId}`;
    const commissionUrl = `${baseUrl}/user-commission`;
    const invoiceUrl = `${baseUrl}/invoices`;

    return (
        <Grid className="profile-grid" container spacing={2}>
            <Grid item md={12} xs={12}>
                <Paper className="table-wrapper">
                    <PaperContainer>
                        <Box>
                            <Tabs value={pathname} variant="scrollable">
                                <Tab
                                    label="Time Tracking"
                                    component={Link}
                                    to={baseUrl}
                                    value={baseUrl}
                                />
                                <Tab
                                    label="User Commissions"
                                    component={Link}
                                    to={commissionUrl}
                                    value={commissionUrl}
                                />
                                <Tab
                                    label="Invoices"
                                    component={Link}
                                    to={invoiceUrl}
                                    value={invoiceUrl}
                                />
                            </Tabs>
                        </Box>
                        <Routes>
                            <Route path="/invoices" element={<InvoiceList />} />
                            <Route
                                path="/user-commission"
                                element={<UserCommissionList />}
                            />
                            <Route
                                path="/"
                                element={<TimeTrackingHoursList />}
                            />
                        </Routes>
                    </PaperContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};
