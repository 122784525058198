import { ReferenceField } from "../interfaces";

export const TerminationReason = {
    abandonedJob: "Abandoned Job",
    attendance: "Attendance",
    completedAssignment: "Completed Assignment",
    performance: "Performance",
    layoff: "Layoff",
    mutualAgreement: "Mutual Agreement",
    misconduct: "Misconduct - Policy Violation",
    personalReasons: "Personal Reasons",
    voluntaryResignation: "Voluntary Resignation",
    shiftChange: "Shift Change",
    titleChange: "Title Change",
    placedInError: "Placed in Error",
    neverStarted: "Never Started",
};

export const getTerminationReasonChoices = () => {
    const choices = Object.values(TerminationReason).map((o) => ({
        label: o,
        value: o,
    }));
    choices.unshift({ label: "", value: "" });
    return choices;
};

export const TerminationReasonChoices: ReferenceField[] =
    getTerminationReasonChoices();
