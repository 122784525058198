import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import {
    CompanyInvoiceRecord,
    PaginatedCompanyInvoiceResponse,
} from "../interfaces";
import { CompanyBillingListProps } from "../interfaces/props";
import { Container, capitalize } from "@mui/material";
import { currencyFormatter } from "../helpers/accounting";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";
import { getOffset } from "../helpers/pagination";
import DataTable, { TableColumn } from "react-data-table-component";
import { createInvoice } from "../api/invoice";

export const CompanyBillingList = (props: CompanyBillingListProps) => {
    const { company } = props;
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [invoices, setInvoices] =
        useState<PaginatedCompanyInvoiceResponse | null>(null);

    const fetchInvoices = async (offset: number, limit: number) => {
        setLoading(true);
        try {
            const api = await getApi();
            const params = { offset, limit };
            const response = await api.get(
                `/time-tracking/company/${company?.id}`,
                { params: params },
            );
            setInvoices(response.data as PaginatedCompanyInvoiceResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchInvoices(0, perPage);
    }, []);

    const handlePageChange = async (page: number) => {
        const offset = getOffset(page, perPage);
        await fetchInvoices(offset, perPage);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        const offset = getOffset(page, newPerPage);
        await fetchInvoices(offset, newPerPage);
    };

    const columns: TableColumn<CompanyInvoiceRecord>[] = [
        {
            name: "Invoice Number",
            selector: (row: CompanyInvoiceRecord) => row.invoiceNumber,
            cell: (row: CompanyInvoiceRecord) =>
                invoices ? (
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            createInvoice(
                                getApi,
                                row.timeTrackingPeriodId,
                                row.companyId,
                                row.id,
                                `${company.name} - ${row.invoiceType} - ${row.invoiceNumber}.pdf`,
                            );
                        }}
                        href="#"
                    >
                        {row.invoiceNumber}
                    </a>
                ) : (
                    <></>
                ),
        },
        {
            name: "Invoice Type",
            selector: (row: CompanyInvoiceRecord) =>
                capitalize(row.invoiceType),
        },
        {
            name: "Invoice Date",
            selector: (row: CompanyInvoiceRecord) => row.invoiceDate ?? "",
        },
        {
            name: "Placements",
            selector: (row: CompanyInvoiceRecord) => row.placements,
        },
        {
            name: "Total Regular Bill",
            selector: (row: CompanyInvoiceRecord) =>
                currencyFormatter(row.totalRegularBill),
        },
        {
            name: "Total OT Bill",
            selector: (row: CompanyInvoiceRecord) =>
                currencyFormatter(row.totalOtBill),
        },
        {
            name: "Total Bill",
            selector: (row: CompanyInvoiceRecord) =>
                currencyFormatter(row.totalBill),
        },
    ];

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h1>{company && `${company?.name ?? "Company"}: `} Invoices</h1>
            <DataTable
                data={invoices?.results ?? []}
                columns={columns}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={invoices?.total ?? 0}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
            />
        </Container>
    );
};
