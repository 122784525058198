import { Button, Container } from "@mui/material";
import { ResumeListProps } from "../interfaces/props";
import { Resume } from "../interfaces";
import { useEffect, useState } from "react";
import { FormSelectField } from "./form/FormSelectField";

export const ResumeList = (props: ResumeListProps) => {
    const [resumes, setResumes] = useState<Resume[] | null>(null);
    const [resume, setResume] = useState<string>("testid");

    useEffect(() => {
        setResumes([
            {
                id: "testid",
                filename: "FakeResumePlaceholder.pdf (2023-09-29)",
            },
        ] as Resume[]);
        setResume("testid");
    }, [props.candidateId]);

    const resumeOptions = (resumes: Resume[] | null) => {
        return resumes
            ? resumes.map((r) => ({ label: r.filename, value: r.id }))
            : [];
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <p className="text-right">
                <Button color="primary" variant="contained">
                    Upload Resume
                </Button>
            </p>
            <form>
                <FormSelectField
                    fullWidth
                    id="resume"
                    name="resume"
                    label="Resume"
                    value={resume}
                    options={resumeOptions(resumes)}
                />
            </form>
        </Container>
    );
};
