import { useContext, useEffect, useState } from "react";
import { CandidatePlacementListProps } from "../interfaces/props";
import { CircularProgress, Container } from "@mui/material";
import DataTable, { TableColumn } from "react-data-table-component";
import { DateFormat, PlacementResponse } from "../interfaces";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { AppContext } from "../context/AppContext";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const CandidatePlacementList = (props: CandidatePlacementListProps) => {
    const { candidateId } = props;
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [placements, setPlacements] = useState<PlacementResponse[] | null>(
        null,
    );

    const getLink = (row: PlacementResponse) => {
        return `/placements/${row.id}`;
    };

    const getCompanyLink = (row: PlacementResponse) => {
        return `/companies/${row.company.id}/profile`;
    };

    const columns: TableColumn<PlacementResponse>[] = [
        {
            name: "Job",
            selector: (row: PlacementResponse) => row.job.title,
            cell: (row: PlacementResponse) => (
                <Link to={getLink(row)}>{row.job.title}</Link>
            ),
        },
        {
            name: "Company",
            selector: (row: PlacementResponse) => row.company.name,
            cell: (row: PlacementResponse) => (
                <Link to={getCompanyLink(row)}>{row.company.name}</Link>
            ),
        },
        {
            name: "Status",
            selector: (row: PlacementResponse) =>
                row.active ? "Current" : "Past",
        },
        {
            name: "Start date",
            selector: (row: PlacementResponse) =>
                dayjs(row.startDate).format(DateFormat),
        },
        {
            name: "End date",
            selector: (row: PlacementResponse) =>
                row.endDate ? dayjs(row.endDate).format(DateFormat) : "",
        },
    ];

    useEffect(() => {
        const fetchPlacements = async () => {
            setLoading(true);
            try {
                const api = await getApi();
                const response = await api.get(
                    `/candidate/${candidateId}/placements`,
                );
                setPlacements(response.data as PlacementResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
            setLoading(false);
        };
        fetchPlacements();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h1>Placements</h1>
            {loading && <CircularProgress />}
            {!loading && placements && (
                <DataTable data={placements} columns={columns} pagination />
            )}
        </Container>
    );
};
