import React from "react";
import ReactDOM from "react-dom/client";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import { AppProvider } from "./context/AppContext";
import history from "./helpers/history";
import { AuthConfig } from "./interfaces/props";
import { getConfig } from "./config";
import "./index.css";

const config: AuthConfig = getConfig();

const onRedirectCallback = (appState?: AppState) => {
    history.push(
        appState && appState.returnTo
            ? appState.returnTo
            : window.location.pathname,
    );
};

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    onRedirectCallback,
    authorizationParams: {
        redirect_uri: window.location.origin,
        ...(config.audience ? { audience: config.audience } : null),
    },
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <Auth0Provider {...providerConfig}>
        <AppProvider>
            <App />
        </AppProvider>
    </Auth0Provider>,
);
