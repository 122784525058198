import { AxiosInstance } from "axios";

export const DateFormat = "YYYY-MM-DD";
export const DatetimeFormat = "MMM DD, YYYY h:mm:ssa";

export interface ApiError {
    message: string;
}

export interface PaginationResponse {
    count: number;
    total: number;
    offset: number;
    limit: number;
}

export interface ReferenceField {
    label: string;
    value: string | number | boolean | null | undefined;
}

export interface OrganizationPermissions {
    hasFrontoffice: boolean;
    hasBackoffice: boolean;
}

export interface Organization {
    id: string;
    name: string;
    permissions?: OrganizationPermissions;
}

export interface PermissionSet {
    role: string;
    hasFrontoffice: boolean;
    hasBackoffice: boolean;
}

export interface OrganizationResponse {
    id: string;
    name: string;
}

export interface UserResponse {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    organization: OrganizationResponse | null;
    organizationId?: string | null;
    commission: CommissionType | null;
}

export interface CompanySiteLocationResponse {
    id: string;
    name: string;
    address: string;
}

export interface CompanySiteLocationForm {
    name: string;
    address: string;
}

export interface CompanyContactResponse {
    id: string;
    name: string;
    title: string;
    mobilePhoneNumber: string | null;
    phoneNumber: string;
    phoneExtension: string | null;
    email: string;
    status: string;
    siteLocation: CompanySiteLocationResponse | null;
}

export interface CompanyContactForm {
    name: string;
    title: string;
    mobilePhoneNumber: string;
    phoneNumber: string;
    phoneExtension: string;
    email: string;
    status: string;
    siteLocationId?: string;
}

export interface CompanyResponse {
    id: string;
    name: string;
    status: string | null;
    website: string | null;
    mobilePhoneNumber: string | null;
    phoneNumber: string | null;
    phoneExtension: string | null;
    hqAddress: string | null;
    billing: string | null;
    billingAddress: string | null;
    hasSiteLocations: boolean;
    created: Date | null;
    updated: Date | null;
    paymentTerms: string | null;
    poNumber: string | null;
    siteLocations: CompanySiteLocationResponse[] | null;
    contacts: CompanyContactResponse[] | null;
}

export interface UserPermissionSet extends UserResponse {
    permissions: PermissionSet;
}

export interface AppContextState {
    getApi: () => Promise<AxiosInstance>;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    login: () => Promise<void>;
    logout: () => Promise<void>;
    user: UserPermissionSet | null;
    getUser: () => Promise<void>;
}

export interface UserFormValue {
    email: string;
    firstName: string;
    lastName: string;
    role?: string;
    organizationId?: string;
    commissionTypeId?: string | null;
}

export interface CompanyFormValue {
    name: string;
    status: string;
    website: string;
    mobilePhoneNumber: string;
    phoneNumber: string;
    phoneExtension: string;
    hqAddress: string;
    billing: string;
    billingAddress: string;
    hasSiteLocations: boolean;
    paymentTerms: string;
    poNumber: string;
}

export interface CandidateForm {
    firstName: string;
    lastName: string;
    suffix: string;
    email: string;
    phoneNumber: string;
    address: string;
    addressUnitNumber: string;
    city: string;
    state: string;
    zipCode: string;
    status: string;
    fileId: string;
    user: string;
}

export interface Candidate {
    id: string;
    firstName: string;
    lastName: string;
    suffix: string;
    email: string;
    phoneNumber: string;
    phoneExtension: string;
    address: string;
    addressUnitNumber: string;
    city: string;
    state: string;
    zipCode: string;
    status: string;
    fileId: string | null;
    created: Date;
    updated: Date | null;
    userAssignedAt: Date | null;
    user: UserResponse | null;
}

export interface PaginatedCandidateResponse extends PaginationResponse {
    results: Candidate[];
}

export interface JobFormBase {
    status: string;
    title: string;
    recurring: boolean;
    type: string;
    level: number;
    startDate: string;
    endDate: string | null;
    perpetual: boolean;
    payRate: number | null;
    billRate: number | null;
    otPayRate: number | null;
    otBillRate: number | null;
    directFee: number | null;
    drugScreen: boolean;
    backgroundCheck: boolean;
    workCompCode: string;
    description: string;
    userId: string | null;
}

export interface JobForm extends JobFormBase {
    siteLocationId?: string;
    workCompCodeId?: string | undefined;
}

export interface JobAddForm extends JobFormBase {
    companyId: string;
    siteLocationId?: string;
    workCompCodeId?: string | undefined;
}

export interface JobResponse extends JobFormBase {
    id: string;
    user: UserResponse;
    company: CompanyResponse;
    siteLocation: CompanySiteLocationResponse;
    wcCode: WorkCompCode | null;
}

export interface StateBase {
    initialized: boolean;
    loading: boolean;
}

export interface JobState extends StateBase {
    jobs: JobResponse[];
}

export interface Resume {
    id: string;
    filename: string;
    created: Date;
}

export interface PlacementResponse {
    id: string;
    startDate: string;
    endDate: string | null;
    guaranteePeriod: string | null;
    terminationReason: string | null;
    payRate: number | null;
    billRate: number | null;
    otPayRate: number | null;
    otBillRate: number | null;
    directFee: number | null;
    assignedUser: UserResponse;
    candidate: Candidate;
    company: CompanyResponse;
    job: JobResponse;
    active?: boolean;
    created?: string;
    updated?: string | null;
}

export interface PaginatedPlacementResponse extends PaginationResponse {
    results: PlacementResponse[];
}

export interface PlacementForm {
    candidateId: string;
    assignedUserId: string;
    startDate: string;
    guaranteePeriod: string | null;
    job: JobResponse | null;
    payRate: number | null;
    billRate: number | null;
    otPayRate: number | null;
    otBillRate: number | null;
    directFee: number | null;
}

export interface PlacementAddForm extends PlacementForm {
    companyId: string;
    jobId: string;
}

export interface PlacementTerminationForm {
    placementId: string;
    endDate: string;
    terminationReason: string;
}

export interface JobFilter {
    field: string;
    value: string | string[];
}

export interface JobSearchForm {
    companyId?: string | string[];
    status?: string | string[];
    type?: string | string[];
}

export interface NoteSearchFilter {
    companyId?: string;
    candidateId?: string;
    userId?: string;
}

export interface NoteForm {
    companyId?: string;
    candidateId?: string;
    text: string;
}

export interface NoteResponse {
    id: string;
    text: string;
    created: string;
    updated: string | null;
    candidateId: string;
    companyId: string;
    user: UserResponse;
}

export interface DocumentBase {
    name: string;
    description: string;
    created: string;
}

export interface DocumentRequest {
    company: string;
    description: string;
    file: File;
}

export interface WorkCompCodeBase {
    code: string;
    description: string;
    location: string;
    percentage: number;
}

export interface WorkCompCode extends WorkCompCodeBase {
    id: string;
}

export type AccountingPeriod = "Monday to Sunday" | "Sunday to Saturday";

export interface AccountingSettings {
    accountingPeriod: AccountingPeriod;
    burdenModification: number;
    coCode: string;
    invoiceStartingNumber: number;
}

export interface CommissionTypeBase {
    name: string;
    percentage: number;
}

export interface CommissionType extends CommissionTypeBase {
    id: string;
}

export interface TimeTrackingRecord {
    id: string;
    hours: number;
    otHours: number;
    totalPay: number;
    totalBill: number;
    totalOtPay: number;
    totalOtBill: number;
    payRate: number;
    billRate: number;
    otPayRate: number;
    otBillRate: number;
    totalDirectFee: number;
    active: boolean;
    timeTrackingPeriodId: string;
    placement: PlacementResponse;
}

export interface TimeTrackingPeriodBase {
    startDate: string;
    endDate: string;
    description: string;
}

export interface TimeTrackingPeriod extends TimeTrackingPeriodBase {
    id: string;
    updated: string;
    records: TimeTrackingRecord[] | null;
}

export interface DateRange {
    start: string;
    end: string;
}

export interface CommissionRecord {
    id: string;
    commission: number;
    user: UserResponse;
}

export interface PlacementCommissionRecord {
    record: TimeTrackingRecord;
    commission: number;
}

export interface Nomination {
    id: string;
    assignedUser: UserResponse;
    candidate: Candidate;
    company: CompanyResponse;
    job: JobResponse;
    active: boolean | null;
    nextStepDate: string | null;
    status: string;
    created: string;
}

export interface PaginatedNominationResponse extends PaginationResponse {
    results: Nomination[];
}

export interface NominationRequest {
    status: string;
    nextStepDate: string | null;
}

export interface InvoiceResponse {
    id: string;
    invoiceNumber: string;
    invoiceDate: string;
    invoiceType: string;
    company: CompanyResponse;
    placements: number;
    totalRegularBill: number;
    totalOtBill: number;
    totalBill: number;
}

export interface CompanyInvoiceResponse {
    placements: number;
    totalRegularBill: number;
    totalOtBill: number;
    totalBill: number;
    invoiceNumber: number;
    invoiceDate: string;
    invoiceType: string;
    records: TimeTrackingRecord[];
}

export interface TimeTrackingInvoiceResponse {
    id: string;
    startDate: string;
    endDate: string;
    description: string | null;
    records: InvoiceResponse[] | null;
}

export interface CompanyInvoiceResponse {
    id: string;
    invoice: CompanyInvoiceResponse;
    startDate: string;
    endDate: string;
    description: string | null;
    company: CompanyResponse;
    records: TimeTrackingRecord[];
}

export interface CompanyInvoiceRecord {
    id: string;
    created: string;
    updated: string;
    placements: number;
    totalRegularBill: number;
    totalOtBill: number;
    totalBill: number;
    totalDirectFee: number;
    invoiceNumber: number;
    invoiceDate: string | null;
    invoiceType: string;
    companyId: string;
    organizationId: string;
    timeTrackingPeriodId: string;
}

export interface PaginatedCompanyInvoiceResponse extends PaginationResponse {
    results: CompanyInvoiceRecord[];
}
