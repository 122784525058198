import { TabPanelProps } from "../interfaces/props";

export const TabPanel = (props: TabPanelProps) => {
    const { index, value, aria, children } = props;

    return (
        <>
            {index === value && (
                <div aria-labelledby={`${aria}-{index}`}>{children}</div>
            )}
        </>
    );
};
