import { CircularProgress } from "@mui/material";
import "../styles/loader.scss";

export const PageLoader = () => {
    return (
        <div className="page-loader-wrapper">
            <CircularProgress />
        </div>
    );
};
