import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    CompanySiteLocationForm,
    CompanySiteLocationResponse,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { Roles, userHasRole } from "../helpers/auth";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const SiteLocationPage = () => {
    const { companyId, siteLocationId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [siteLocation, setSiteLocation] =
        useState<CompanySiteLocationResponse | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSiteLocation = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/company/${companyId}/site-location/${siteLocationId}`,
                );
                const responseSiteLocation: CompanySiteLocationResponse =
                    response.data as CompanySiteLocationResponse;
                setSiteLocation(responseSiteLocation);
                formik.setValues({
                    name: responseSiteLocation.name,
                    address: responseSiteLocation.address,
                });
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchSiteLocation();
    }, []);

    const saveSiteLocation = async (
        newSiteLocation: CompanySiteLocationForm,
    ) => {
        if (!appUser) {
            setLoading(false);
            return;
        }
        try {
            const api = await getApi();
            const response = await api.put(
                `/company/${companyId}/site-location/${siteLocationId}`,
                newSiteLocation,
            );
            if (response.status === 200) {
                navigate(`/companies/${companyId}/profile`);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        address: yup.string().required("Address is required"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            address: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: CompanySiteLocationForm) => {
            setLoading(true);
            saveSiteLocation(values);
        },
    });

    const canEdit = () => {
        return appUser
            ? userHasRole(appUser, [Roles.admin, Roles.systemAdmin])
            : false;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>
                        {siteLocation ? siteLocation.name : "Site Location"}
                    </h1>
                    <form onSubmit={formik.handleSubmit}>
                        {siteLocation && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    value={siteLocation?.id}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="address"
                                    name="address"
                                    label="Address"
                                    type="text"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.address &&
                                        Boolean(formik.errors.address)
                                    }
                                    helperText={
                                        formik.touched.address &&
                                        formik.errors.address
                                    }
                                    disabled={loading || !canEdit()}
                                    multiline
                                    rows={5}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading || !canEdit()}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
