import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import { WorkCompCode } from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { StateChoices } from "../helpers/location";
import { handleError } from "../helpers/error";

export const WorkCompCodePage = () => {
    const { compCodeId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [compCode, setCompCode] = useState<WorkCompCode | null>(null);

    const navigate = useNavigate();

    const saveWorkCompCode = async (newWorkCompCode: WorkCompCode) => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.put(
                `/comp-code/${newWorkCompCode.id}`,
                newWorkCompCode,
            );
            setCompCode(response.data as WorkCompCode);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
        navigate("/backoffice");
    };

    const validationSchema = yup.object({
        id: yup.string().required("ID is required"),
        code: yup.string().required("Code is required"),
        location: yup.string().required("Location is required"),
        description: yup.string().required("Description is required"),
        percentage: yup.number().required("Percentage is required"),
    });

    const formik = useFormik({
        initialValues: {
            id: compCodeId ?? "",
            code: "",
            location: "",
            description: "",
            percentage: 0.0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: WorkCompCode, { resetForm }) => {
            await saveWorkCompCode(values);
            resetForm();
        },
    });

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get(`/comp-code/${compCodeId}`);
            const compCodeResponse = response.data as WorkCompCode;
            setCompCode(compCodeResponse);
            formik.setValues(compCodeResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Edit Worker Comp Code</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {compCode && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="code"
                                    name="code"
                                    label="Code"
                                    type="text"
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.code &&
                                        Boolean(formik.errors.code)
                                    }
                                    helperText={
                                        formik.touched.code &&
                                        formik.errors.code
                                    }
                                    disabled={loading}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="location"
                                    name="location"
                                    label="Location"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.location &&
                                        Boolean(formik.errors.location)
                                    }
                                    options={StateChoices}
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="description"
                                    name="description"
                                    label="Description"
                                    type="text"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="percentage"
                                    name="percentage"
                                    label="Percentage ($100 of payroll as percentage)"
                                    type="number"
                                    value={formik.values.percentage}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.percentage &&
                                        Boolean(formik.errors.percentage)
                                    }
                                    helperText={
                                        formik.touched.percentage &&
                                        formik.errors.percentage
                                    }
                                    disabled={loading}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
