import { ReferenceField } from "../interfaces";

export const CompanyStatus = {
    prospect: "Prospect",
    active: "Active",
    inactive: "Inactive",
};

export const BillingType = {
    companyBilling: "Company Billing",
    siteBilling: "Site Billing",
};

export const CompanyContactStatus = {
    active: "Active",
    inactive: "Inactive",
};

export const PaymentTerms = {
    dueOnReceipt: "Due on Receipt",
    net30: "Net 30",
    net45: "Net 45",
    net60: "Net 60",
    net90: "Net 90",
};

export const CompanyStatusChoices: ReferenceField[] = Object.values(
    CompanyStatus,
).map((status) => ({ label: status, value: status }));

export const BillingTypeChoices: ReferenceField[] = Object.values(
    BillingType,
).map((billing) => ({ label: billing, value: billing }));

export const CompanyContactStatusChoices: ReferenceField[] = Object.values(
    CompanyContactStatus,
).map((status) => ({ label: status, value: status }));

export const PaymentTermChoices: ReferenceField[] = Object.values(
    PaymentTerms,
).map((term) => ({ label: term, value: term }));
