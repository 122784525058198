import { Container, Paper } from "@mui/material";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import { PaperContainer } from "../components/PaperContainer";
import DataTable, { TableColumn } from "react-data-table-component";
import {
    DateFormat,
    PaginatedPlacementResponse,
    PlacementResponse,
} from "../interfaces";
import { Link } from "react-router-dom";
import { getFullName } from "../helpers/user";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { getOffset } from "../helpers/pagination";
import { PlacementsListProps } from "../interfaces/props";
import { formatName } from "../helpers/candidate";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const PlacementsList = (props: PlacementsListProps) => {
    const { active } = props;
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [showAll, setShowAll] = useState<boolean>(false);
    const [placements, setPlacements] =
        useState<PaginatedPlacementResponse | null>(null);

    const getCandidateLink = (row: PlacementResponse) => {
        return `/candidates/${row.candidate.id}/profile`;
    };

    const getCompanyLink = (row: PlacementResponse) => {
        return `/companies/${row.company.id}/profile`;
    };

    const getUserLink = (row: PlacementResponse) => {
        return `/users/${row.assignedUser.id}`;
    };

    const fetchData = async (offset: number, limit: number) => {
        setLoading(true);
        try {
            const request = {
                fields: {
                    active,
                },
                orderBy: active ? "start_date" : "end_date",
                order: "desc",
                offset,
                limit: showAll ? -1 : limit,
            };
            const api = await getApi();
            const response = await api.post("/placement/search", request);
            const paginationResponse: PaginatedPlacementResponse =
                response.data;
            setPlacements(paginationResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData(0, perPage);
    }, [showAll]);

    // Candidate Name, Type, Company, Start Date, Pay Rate, Bill Rate, Recruiter Assigned
    const activeColumns: TableColumn<PlacementResponse>[] = [
        {
            name: "Name",
            selector: (row: PlacementResponse) => row.candidate.firstName,
            cell: (row: PlacementResponse) => (
                <Link to={getCandidateLink(row)}>
                    {formatName(row.candidate)}
                </Link>
            ),
        },
        {
            name: "Type",
            selector: (row: PlacementResponse) => row.job.type,
        },
        {
            name: "Company",
            selector: (row: PlacementResponse) => row.company.name,
            cell: (row: PlacementResponse) => (
                <Link to={getCompanyLink(row)}>{row.company.name}</Link>
            ),
        },
        {
            name: "Start date",
            selector: (row: PlacementResponse) =>
                dayjs(row.startDate).format(DateFormat),
        },
        {
            name: "Pay rate",
            selector: (row: PlacementResponse) =>
                row.payRate ? `$${row.payRate.toFixed(2)}` : "N/A",
        },
        {
            name: "Bill rate",
            selector: (row: PlacementResponse) =>
                row.billRate ? `$${row.billRate.toFixed(2)}` : "N/A",
        },
        {
            name: "OT pay rate",
            selector: (row: PlacementResponse) =>
                row.otPayRate ? `$${row.otPayRate.toFixed(2)}` : "N/A",
        },
        {
            name: "OT bill rate",
            selector: (row: PlacementResponse) =>
                row.otBillRate ? `$${row.otBillRate.toFixed(2)}` : "N/A",
        },
        {
            name: "Recruiter assigned",
            selector: (row: PlacementResponse) => getFullName(row.assignedUser),
            cell: (row: PlacementResponse) => (
                <Link to={getUserLink(row)}>
                    {getFullName(row.assignedUser)}
                </Link>
            ),
        },
    ];

    const pastColumns: TableColumn<PlacementResponse>[] = [
        {
            name: "Name",
            selector: (row: PlacementResponse) => row.candidate.firstName,
            cell: (row: PlacementResponse) => (
                <Link to={getCandidateLink(row)}>
                    {formatName(row.candidate)}
                </Link>
            ),
        },
        {
            name: "Type",
            selector: (row: PlacementResponse) => row.job.type,
        },
        {
            name: "Company",
            selector: (row: PlacementResponse) => row.company.name,
            cell: (row: PlacementResponse) => (
                <Link to={getCompanyLink(row)}>{row.company.name}</Link>
            ),
        },
        {
            name: "Start date",
            selector: (row: PlacementResponse) =>
                dayjs(row.startDate).format(DateFormat),
        },
        {
            name: "End date",
            selector: (row: PlacementResponse) =>
                row.endDate ? dayjs(row.endDate).format(DateFormat) : "",
        },
        {
            name: "Termination reason",
            selector: (row: PlacementResponse) => row.terminationReason ?? "",
        },
        {
            name: "Recruiter assigned",
            selector: (row: PlacementResponse) => getFullName(row.assignedUser),
            cell: (row: PlacementResponse) => (
                <Link to={getUserLink(row)}>
                    {getFullName(row.assignedUser)}
                </Link>
            ),
        },
    ];

    const handlePageChange = async (page: number) => {
        const offset = getOffset(page, perPage);
        await fetchData(offset, perPage);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        const offset = getOffset(page, newPerPage);
        await fetchData(offset, newPerPage);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>{active ? "Active" : "Past"} Placements</h1>
                    {active && (
                        <>
                            <Switch
                                checked={showAll}
                                onChange={() => setShowAll(!showAll)}
                            />{" "}
                            Show All
                        </>
                    )}
                    <DataTable
                        data={placements?.results ?? []}
                        columns={active ? activeColumns : pastColumns}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={placements?.total ?? 0}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
