import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { CompanyContactResponse } from "../interfaces";
import { Button } from "@mui/material";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { ContactsListProps } from "../interfaces/props";
import { Roles, userHasRole } from "../helpers/auth";

export const ContactsList = (props: ContactsListProps) => {
    const { company, user } = props;

    const getLink = (row: CompanyContactResponse) => {
        return `/companies/${company?.id}/contacts/${row.id}`;
    };

    const columns: TableColumn<CompanyContactResponse>[] = [
        {
            name: "Name",
            selector: (row: CompanyContactResponse) => row.name,
            cell: (row: CompanyContactResponse) => (
                <Link to={getLink(row)}>{row.name}</Link>
            ),
        },
        {
            name: "Email",
            selector: (row: CompanyContactResponse) => row.email,
            cell: (row: CompanyContactResponse) => (
                <Link to={getLink(row)}>{row.email}</Link>
            ),
        },
        {
            name: "Office phone",
            selector: (row: CompanyContactResponse) =>
                formatPhoneNumber(row.phoneNumber ?? ""),
        },
        {
            name: "Status",
            selector: (row: CompanyContactResponse) => row.status ?? "",
        },
        {
            name: "Site Location",
            selector: (row: CompanyContactResponse) =>
                row.siteLocation?.name ?? "HQ",
        },
    ];

    return (
        <>
            {company && (
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <h1>{`${company?.name}: ` ?? ""}Contacts</h1>
                    <p>
                        {user &&
                            userHasRole(user, [
                                Roles.admin,
                                Roles.systemAdmin,
                            ]) && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component={Link}
                                    to={`/companies/${company?.id}/contacts/add`}
                                >
                                    Add Contact
                                </Button>
                            )}
                    </p>
                    <DataTable
                        data={company?.contacts ?? []}
                        columns={columns}
                        pagination
                    />
                </Container>
            )}
        </>
    );
};
