import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { NotesListProps } from "../interfaces/props";
import { AppContext } from "../context/AppContext";
import { DatetimeFormat, NoteForm, NoteResponse } from "../interfaces";
import { FormikState, useFormik } from "formik";
import { FormTextField } from "./form/FormTextField";
import { Divider, Paper } from "@mui/material";
import { getFullName } from "../helpers/user";
import { PaperContainer } from "./PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const NotesList = (props: NotesListProps) => {
    const { filter } = props;
    const { getApi } = useContext(AppContext);
    const [notes, setNotes] = useState<NoteResponse[]>([] as NoteResponse[]);
    const [loading, setLoading] = useState<boolean>(false);

    const getNotes = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.post("/note/search", filter);
            setNotes(response.data as NoteResponse[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const postNote = async (newNote: NoteForm) => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.post("/note", newNote);
            const noteResponse = response.data as NoteResponse;
            setNotes([noteResponse, ...notes]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        text: yup.string().required("Note text is required"),
    });

    const initialFormValues = {
        ...filter,
        text: "",
    };

    const formik = useFormik({
        initialValues: initialFormValues,
        validationSchema: validationSchema,
        onSubmit: (values: NoteForm, { resetForm }) => {
            postNote(values);
            resetForm(initialFormValues as Partial<FormikState<NoteForm>>);
        },
    });

    useEffect(() => {
        getNotes();
    }, [filter]);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h3>Add Note</h3>
            <form onSubmit={formik.handleSubmit}>
                <FormTextField
                    fullWidth
                    id="text"
                    name="text"
                    label="Note Text"
                    type="text"
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.text && Boolean(formik.errors.text)}
                    helperText={formik.touched.text && formik.errors.text}
                    disabled={loading}
                    multiline
                    rows={5}
                />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Add Note
                </Button>
            </form>

            <Divider style={{ marginTop: "2em" }} variant="middle" />

            <div>
                <h3>Notes</h3>
                {notes.length === 0 && <p>No notes</p>}
                {notes.map((note: NoteResponse) => {
                    return (
                        <Paper
                            key={`note-${note.id}`}
                            elevation={2}
                            style={{ marginBottom: "1em" }}
                        >
                            <PaperContainer>
                                <p className="meta-info">
                                    {getFullName(note.user)} &bull;{" "}
                                    {dayjs(note.created).format(DatetimeFormat)}
                                </p>
                                <div className="show-newlines">{note.text}</div>
                            </PaperContainer>
                        </Paper>
                    );
                })}
            </div>
        </Container>
    );
};
