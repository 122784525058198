import { StyleSheet, Text, View } from "@react-pdf/renderer";

const borderColor = "#E3E4E5";
const textColor = "#54585B";
export const styles = StyleSheet.create({
    footer: {
        color: textColor,
        borderColor: borderColor,
        borderTopWidth: 1,
        position: "absolute",
        fontSize: 12,
        bottom: 0,
        left: "20px",
        right: 0,
        textAlign: "center",
        width: "100%",
        padding: "20px 20px",
    },
    boldText: {
        fontFamily: "Helvetica-Bold",
    },
});

export const InvoiceFooter = () => {
    return (
        <View fixed style={styles.footer}>
            <Text style={styles.boldText}>Synergy Solutions</Text>
            <Text>Royal Oak, MI</Text>
            <Text>(313) 315-3018</Text>
        </View>
    );
};
