import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    BaseSingleInputFieldProps,
    DateTimeField,
    DateTimeValidationError,
    DateValidationError,
    FieldSection,
    LocalizationProvider,
    UseDateTimeFieldProps,
} from "@mui/x-date-pickers";
import { UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { Dayjs } from "dayjs";

interface FormDateFieldProps
    extends UseDateFieldProps<Dayjs>, // The headless field props
        BaseSingleInputFieldProps<
            Dayjs | null,
            Dayjs,
            FieldSection,
            DateValidationError
        > {}

interface FormDateTimeFieldProps
    extends UseDateTimeFieldProps<Dayjs>, // The headless field props
        BaseSingleInputFieldProps<
            Dayjs | null,
            Dayjs,
            FieldSection,
            DateTimeValidationError
        > {}

export const FormDateField = (props: FormDateFieldProps) => {
    return (
        <div className="form-field">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker {...props} />
            </LocalizationProvider>
        </div>
    );
};

export const FormDateTimeField = (props: FormDateTimeFieldProps) => {
    return (
        <div className="form-field">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimeField {...props} />
            </LocalizationProvider>
        </div>
    );
};
