import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import { UserResponse } from "../interfaces";
import { Button, Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const UsersPage = () => {
    const { getApi } = useContext(AppContext);
    const [users, setUsers] = useState<UserResponse[]>([] as UserResponse[]);

    const getLink = (row: UserResponse) => {
        return `/users/${row.id}`;
    };

    const getFullName = (user: UserResponse): string => {
        return user.firstName + " " + user.lastName;
    };

    const columns: TableColumn<UserResponse>[] = [
        {
            name: "ID",
            selector: (row: UserResponse) => row.id,
            cell: (row: UserResponse) => (
                <Link to={getLink(row)}>{row.id}</Link>
            ),
        },
        {
            name: "Name",
            selector: (row: UserResponse) => row.firstName,
            cell: (row: UserResponse) => (
                <Link to={getLink(row)}>{getFullName(row)}</Link>
            ),
        },
        {
            name: "Organization",
            selector: (row: UserResponse) => row.organization?.name ?? "",
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/iam/user");
                setUsers(response.data as UserResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Users</h1>
                    <p>
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={"/users/add"}
                        >
                            Add User
                        </Button>
                    </p>
                    <DataTable data={users} columns={columns} pagination />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
