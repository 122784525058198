import { Link } from "react-router-dom";
import {
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { CandidateSidebarProps } from "../../interfaces/props";
import { PaperContainer } from "../PaperContainer";
import { formatPhoneNumber } from "../../helpers/phoneNumber";
import { Candidate } from "../../interfaces";
import { formatName } from "../../helpers/candidate";

export const CandidateSidebar = (props: CandidateSidebarProps) => {
    const { buttonActions, candidate } = props;

    const formatAddress = (c: Candidate | null) => {
        if (!c) return "";

        const pieces: string[] = [
            c?.address,
            c?.addressUnitNumber,
            `${c.city}, ${c.state} ${c.zipCode}`,
        ].filter((p) => p && p !== "");

        return pieces.join("\n");
    };

    return (
        <Paper>
            <PaperContainer>
                <div className="text-center">
                    <h2>
                        {candidate ? formatName(candidate) : "Candidate"}{" "}
                        {candidate && (
                            <IconButton
                                component={Link}
                                to={`/candidate/${candidate?.id}`}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </h2>
                    <p>Status ({candidate?.status})</p>
                    <p>{candidate?.id}</p>
                    <p>{candidate?.email}</p>
                    <p>
                        {candidate && formatPhoneNumber(candidate?.phoneNumber)}
                    </p>
                    <p className="show-newlines">{formatAddress(candidate)}</p>
                    {candidate?.fileId && <p>File ID: {candidate?.fileId}</p>}

                    <Divider variant="middle" />

                    <List>
                        <ListItemButton
                            onClick={() => buttonActions("nominate")}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="Nominate"
                            ></ListItemText>
                        </ListItemButton>
                        <ListItemButton onClick={() => buttonActions("place")}>
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="Place"
                            ></ListItemText>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => buttonActions("logNote")}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="Log Note"
                            ></ListItemText>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => buttonActions("deleteTalent")}
                        >
                            <ListItemText
                                style={{ textAlign: "center" }}
                                primary="Delete Talent"
                            ></ListItemText>
                        </ListItemButton>
                    </List>
                </div>
            </PaperContainer>
        </Paper>
    );
};
