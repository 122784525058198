import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { AccountingPeriod, AccountingSettings } from "../interfaces";
import { AppContext } from "../context/AppContext";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";
import {
    AccountingPeriodChoices,
    AccountingPeriods,
} from "../helpers/accounting";

export const AccountingSettingsPqage = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);

    const saveSettings = async (newSettings: AccountingSettings) => {
        setLoading(true);
        try {
            const api = await getApi();
            await api.put("/accounting/settings", newSettings);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        accountingPeriod: yup
            .string()
            .required("Accounting period is required"),
    });

    const formik = useFormik({
        initialValues: {
            accountingPeriod:
                AccountingPeriods.mondayToSunday as AccountingPeriod,
            burdenModification: 0.0,
            coCode: "",
            invoiceStartingNumber: 0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: AccountingSettings) => {
            await saveSettings(values);
        },
    });

    const fetchData = async () => {
        setLoading(true);
        const api = await getApi();
        try {
            const response = await api.get("/accounting/settings");
            const settings = response.data as AccountingSettings;
            formik.setValues({
                accountingPeriod: settings.accountingPeriod,
                burdenModification: settings.burdenModification,
                coCode: settings.coCode,
                invoiceStartingNumber: settings.invoiceStartingNumber,
            });
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <h3>Accounting Settings</h3>
            <form onSubmit={formik.handleSubmit}>
                <FormSelectField
                    fullWidth
                    id="accountingPeriod"
                    name="accountingPeriod"
                    label="Accounting Period"
                    value={formik.values.accountingPeriod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.accountingPeriod &&
                        Boolean(formik.errors.accountingPeriod)
                    }
                    options={AccountingPeriodChoices}
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="burdenModification"
                    name="burdenModification"
                    label="Burden modification"
                    type="text"
                    value={formik.values.burdenModification}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.burdenModification &&
                        Boolean(formik.errors.burdenModification)
                    }
                    helperText={
                        formik.touched.burdenModification &&
                        formik.errors.burdenModification
                    }
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="coCode"
                    name="coCode"
                    label="CO Code"
                    type="text"
                    value={formik.values.coCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.coCode && Boolean(formik.errors.coCode)
                    }
                    helperText={formik.touched.coCode && formik.errors.coCode}
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="invoiceStartingNumber"
                    name="invoiceStartingNumber"
                    label="Invoice Starting Number"
                    type="text"
                    value={formik.values.invoiceStartingNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.invoiceStartingNumber &&
                        Boolean(formik.errors.invoiceStartingNumber)
                    }
                    helperText={
                        formik.touched.invoiceStartingNumber &&
                        formik.errors.invoiceStartingNumber
                    }
                    disabled={loading}
                />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Save Settings
                </Button>
            </form>
        </>
    );
};
