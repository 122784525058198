import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    CommissionType,
    ReferenceField,
    UserFormValue,
    UserPermissionSet,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { Roles, SelectableRoles, userHasRole } from "../helpers/auth";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const UserPage = () => {
    const { userId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<UserPermissionSet | null>(null);
    const [commissionTypes, setCommissionTypes] = useState<
        CommissionType[] | null
    >(null);

    const saveUser = async (newUser: UserFormValue) => {
        if (!user) return;
        const payload = {
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
        } as UserFormValue;
        if (
            userHasRole(appUser as UserPermissionSet, [
                Roles.systemAdmin,
                Roles.admin,
            ])
        ) {
            payload.role = newUser.role;
            payload.commissionTypeId = newUser.commissionTypeId;
        }
        try {
            const api = await getApi();
            const response = await api.put(`/iam/user/${user?.id}`, payload);
            setUser(response.data as UserPermissionSet);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is required"),
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        role: yup.string().required("Role is required"),
        commissionTypeId: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            role: "",
            commissionTypeId: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: UserFormValue) => {
            setLoading(true);
            saveUser(values);
        },
    });

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/iam/user/${userId}`);
                const responseUser: UserPermissionSet =
                    response.data as UserPermissionSet;
                setUser(responseUser);
                formik.setValues({
                    email: responseUser.email,
                    firstName: responseUser.firstName,
                    lastName: responseUser.lastName,
                    role: responseUser.permissions?.role,
                    commissionTypeId: responseUser.commission?.id,
                });
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchCommissionTypes = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/accounting/commission-type");
                setCommissionTypes(response.data as CommissionType[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchUser();
        if (appUser && userHasRole(appUser, [Roles.admin, Roles.systemAdmin])) {
            fetchCommissionTypes();
        }
    }, []);

    const getFullName = (user: UserPermissionSet): string => {
        return user.firstName + " " + user.lastName;
    };

    const commissionTypeChoices: ReferenceField[] = commissionTypes
        ? commissionTypes.map((commissionType: CommissionType) => {
              return {
                  label: `${commissionType.name} - ${commissionType.percentage}`,
                  value: commissionType.id,
              };
          })
        : [];
    commissionTypeChoices.unshift({ label: "None", value: null });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>{user ? getFullName(user) : "User"}</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {user && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    value={user?.id}
                                    disabled
                                />

                                <FormTextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    disabled={loading}
                                />

                                <FormTextField
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    type="text"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    type="text"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.lastName &&
                                        Boolean(formik.errors.lastName)
                                    }
                                    helperText={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                    }
                                    disabled={loading}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="role"
                                    name="role"
                                    label="Role"
                                    value={formik.values.role}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.role &&
                                        Boolean(formik.errors.role)
                                    }
                                    options={SelectableRoles}
                                    disabled={
                                        !userHasRole(
                                            appUser as UserPermissionSet,
                                            [Roles.systemAdmin],
                                        ) || loading
                                    }
                                />
                                {appUser &&
                                    userHasRole(appUser, [
                                        Roles.admin,
                                        Roles.systemAdmin,
                                    ]) && (
                                        <FormSelectField
                                            fullWidth
                                            id="commissionTypeId"
                                            name="commissionTypeId"
                                            label="Commission type"
                                            value={
                                                formik.values.commissionTypeId
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched
                                                    .commissionTypeId &&
                                                Boolean(
                                                    formik.errors
                                                        .commissionTypeId,
                                                )
                                            }
                                            options={commissionTypeChoices}
                                            disabled={loading}
                                        />
                                    )}
                                <div className="form-field">
                                    <b>Frontoffice:</b>{" "}
                                    {user.permissions?.hasFrontoffice.toString()}
                                </div>
                                <div className="form-field">
                                    <b>Backoffice:</b>{" "}
                                    {user.permissions?.hasBackoffice.toString()}
                                </div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
