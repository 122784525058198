import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { CompanyInvoiceDocumentProps } from "../interfaces/props";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { CompanyResponse } from "../interfaces";
import InvoiceTable from "./invoice/InvoiceTable";
import { InvoiceFooter } from "./invoice/InvoiceFooter";
import { currencyFormatter } from "../helpers/accounting";

const textColor = "#54585B";
const styles = StyleSheet.create({
    page: {
        padding: "20px 20px 80px 20px",
    },
    bodyText: {
        color: textColor,
        fontSize: 12,
        marginBottom: 3,
    },
    boldText: {
        fontFamily: "Helvetica-Bold",
        color: textColor,
        fontSize: 12,
        marginBottom: 3,
    },
    bottomMargin: {
        marginBottom: 20,
    },
    headerTitle: {
        color: "#fff",
        fontSize: 28,
    },
    headerSub: {
        color: "#fff",
        fontSize: 12,
    },
    headerSection: {
        backgroundColor: "#63A5E8",
        padding: "30px 20px",
        position: "relative",
    },
    headerPrice: {
        backgroundColor: "#4F84B7",
        padding: "30px 20px",
        position: "absolute",
        top: 0,
        right: 0,
        width: "35%",
        textAlign: "center",
    },
    pageHeader: {
        color: "#A1A5A9",
        fontSize: 12,
        marginBottom: 2,
        textTransform: "uppercase",
    },
    billingSection: {
        padding: "20px 0px",
    },
    billingRightColumn: {
        padding: "34px 20px 30px 0px",
        position: "absolute",
        top: 0,
        right: 0,
        width: "35%",
    },
});

export const CompanyInvoiceDocument = (props: CompanyInvoiceDocumentProps) => {
    const { invoice } = props;
    console.log(invoice);

    const formatBillingAddress = (company: CompanyResponse) => {
        if (company.billingAddress && company.billingAddress.length > 0) {
            return company.billingAddress;
        } else {
            return company.hqAddress;
        }
    };
    return (
        <Document>
            <Page style={styles.page}>
                {/* header */}
                <View style={styles.headerSection}>
                    <Text style={styles.headerTitle}>INVOICE</Text>
                    <Text style={styles.headerSub}>Synergy Solutions</Text>
                    <View style={styles.headerPrice}>
                        <Text style={styles.headerSub}>Amount Due</Text>
                        <Text style={styles.headerTitle}>
                            {currencyFormatter(invoice.invoice.totalBill)}
                        </Text>
                    </View>
                </View>
                {/* billing info */}
                <View style={styles.billingSection}>
                    <View>
                        <Text style={styles.pageHeader}>Bill To</Text>
                        <Text style={styles.boldText}>
                            {invoice.company.name}
                        </Text>
                        <Text style={styles.bodyText}>
                            {formatBillingAddress(invoice.company)}
                        </Text>
                        <Text style={styles.bodyText}>
                            {invoice.company.phoneNumber
                                ? formatPhoneNumber(invoice.company.phoneNumber)
                                : ""}
                            {invoice.company.phoneExtension
                                ? ` ${invoice.company.phoneExtension}`
                                : ""}
                        </Text>
                    </View>
                    <View style={styles.billingRightColumn}>
                        <Text style={styles.bodyText}>
                            Invoice Number: {` ${invoice.invoiceNumber}`}
                        </Text>
                        {invoice.company.poNumber &&
                            invoice.company.poNumber.length > 0 && (
                                <Text
                                    style={{
                                        ...styles.bodyText,
                                        marginLeft: 20,
                                    }}
                                >
                                    PO Number: {` ${invoice.company.poNumber}`}
                                </Text>
                            )}
                        <Text style={{ ...styles.bodyText, marginLeft: 18 }}>
                            Invoice Date: {` ${invoice.endDate}`}
                        </Text>
                        <Text style={{ ...styles.bodyText, marginLeft: 13 }}>
                            Payment Due: {`  ${invoice.company.paymentTerms}`}
                        </Text>
                    </View>
                </View>
                {/* invoice items */}
                <InvoiceTable invoice={invoice} />
                {/* invoice footer */}
                <InvoiceFooter />
            </Page>
        </Document>
    );
};
