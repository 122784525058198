import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    CompanyContactForm,
    CompanySiteLocationResponse,
    ReferenceField,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import {
    CompanyContactStatus,
    CompanyContactStatusChoices,
} from "../helpers/company";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const ContactAddPage = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [siteLocations, setSiteLocations] = useState<
        CompanySiteLocationResponse[] | null
    >(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSiteLocations = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/company/${companyId}/site-location`,
                );
                setSiteLocations(
                    response.data as CompanySiteLocationResponse[],
                );
            } catch (e) {
                handleError(e as AxiosError);
            }
        };

        fetchSiteLocations();
    }, []);

    const saveContact = async (newContact: CompanyContactForm) => {
        if (!appUser) {
            setLoading(false);
            return;
        }
        try {
            const api = await getApi();
            const response = await api.post(
                `/company/${companyId}/contact`,
                newContact,
            );
            if (response.status === 201) {
                navigate(`/companies/${companyId}/profile`);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        title: yup.string().required("Title is required"),
        mobilePhoneNumber: yup.string().nullable(),
        phoneNumber: yup.string().required("Office phone number is required"),
        phoneExtension: yup.string().nullable(),
        email: yup.string().required("Email is required"),
        status: yup.string().required("Status is required"),
        siteLocationId: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            title: "",
            mobilePhoneNumber: "",
            phoneNumber: "",
            phoneExtension: "",
            email: "",
            status: CompanyContactStatus.active,
            siteLocationId: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: (values: CompanyContactForm) => {
            setLoading(true);
            saveContact(values);
        },
    });

    const siteLocationChoices: ReferenceField[] = siteLocations
        ? siteLocations.map((siteLocation: CompanySiteLocationResponse) => {
              return {
                  label: siteLocation.name,
                  value: siteLocation.id,
              };
          })
        : [];
    siteLocationChoices.unshift({ label: "None", value: undefined });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Add Contact</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <FormTextField
                                fullWidth
                                id="name"
                                name="name"
                                label="Name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="title"
                                name="title"
                                label="Title"
                                type="text"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.title &&
                                    Boolean(formik.errors.title)
                                }
                                helperText={
                                    formik.touched.title && formik.errors.title
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Office phone number"
                                type="text"
                                value={formatPhoneNumber(
                                    formik.values.phoneNumber,
                                )}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.phoneNumber &&
                                    Boolean(formik.errors.phoneNumber)
                                }
                                helperText={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="phoneExtension"
                                name="phoneExtension"
                                label="Office phone extension"
                                type="text"
                                value={formatPhoneNumber(
                                    formik.values.phoneExtension,
                                )}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.phoneExtension &&
                                    Boolean(formik.errors.phoneExtension)
                                }
                                helperText={
                                    formik.touched.phoneExtension &&
                                    formik.errors.phoneExtension
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="mobilePhoneNumber"
                                name="mobilePhoneNumber"
                                label="Mobile phone number"
                                type="text"
                                value={formatPhoneNumber(
                                    formik.values.mobilePhoneNumber,
                                )}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.mobilePhoneNumber &&
                                    Boolean(formik.errors.mobilePhoneNumber)
                                }
                                helperText={
                                    formik.touched.mobilePhoneNumber &&
                                    formik.errors.mobilePhoneNumber
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                type="text"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="status"
                                name="status"
                                label="Status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.status &&
                                    Boolean(formik.errors.status)
                                }
                                options={CompanyContactStatusChoices}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="siteLocationId"
                                name="siteLocationId"
                                label="Site Location"
                                value={formik.values.siteLocationId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.siteLocationId &&
                                    Boolean(formik.errors.siteLocationId)
                                }
                                options={siteLocationChoices}
                                disabled={loading}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Add Contact
                            </Button>
                        </>
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
