import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { getAppRouter } from "./router";
import { AppContext } from "./context/AppContext";
import "./App.scss";
import { PageLoader } from "./components/Loader";

function App() {
    const { isAuthenticated, isAuthenticating, login, user, getUser } =
        useContext(AppContext);

    useEffect(() => {
        if (!isAuthenticated && !isAuthenticating) login();
        else if (isAuthenticated && !isAuthenticating) getUser();
    }, [isAuthenticating, isAuthenticated, login]);

    // show blank page before redirecting to auth
    // return <PageLoader />;
    return user ? (
        <RouterProvider router={getAppRouter(user)} />
    ) : (
        <PageLoader />
    );
}

export default App;
