import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { CompanyResponse } from "../interfaces";
import { SiteLocationsList } from "../components/SiteLocationsList";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const SiteLocationsPage = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [company, setCompany] = useState<CompanyResponse>(
        {} as CompanyResponse,
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/company/${companyId}`);
                setCompany(response.data as CompanyResponse);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchData();
    }, []);

    return <SiteLocationsList company={company} user={appUser} />;
};
