import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import { CompanyResponse } from "../interfaces";
import { Button, Paper } from "@mui/material";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { PaperContainer } from "../components/PaperContainer";
import { Roles, userHasRole } from "../helpers/auth";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const CompaniesPage = () => {
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [companies, setCompanies] = useState<CompanyResponse[]>(
        [] as CompanyResponse[],
    );

    const getLink = (row: CompanyResponse) => {
        return `/companies/${row.id}/profile`;
    };

    const columns: TableColumn<CompanyResponse>[] = [
        {
            name: "Name",
            selector: (row: CompanyResponse) => row.name,
            cell: (row: CompanyResponse) => (
                <Link to={getLink(row)}>{row.name}</Link>
            ),
        },
        {
            name: "Status",
            selector: (row: CompanyResponse) => row.status ?? "",
        },
        {
            name: "Office phone number",
            selector: (row: CompanyResponse) =>
                formatPhoneNumber(row.phoneNumber ?? ""),
        },
        {
            name: "Office extension",
            selector: (row: CompanyResponse) =>
                formatPhoneNumber(row.phoneExtension ?? ""),
        },
        {
            name: "Mobile phone number",
            selector: (row: CompanyResponse) =>
                formatPhoneNumber(row.mobilePhoneNumber ?? ""),
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const api = await getApi();
                const response = await api.get("/company");
                setCompanies(response.data as CompanyResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
            setLoading(false);
        };
        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Companies</h1>
                    <p>
                        {appUser &&
                            userHasRole(appUser, [
                                Roles.admin,
                                Roles.systemAdmin,
                            ]) && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component={Link}
                                    to={"/companies/add"}
                                >
                                    Add Company
                                </Button>
                            )}
                    </p>
                    <DataTable
                        data={companies}
                        columns={columns}
                        progressPending={loading}
                        pagination
                    />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
