import { Container, Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import DataTable, { TableColumn, SortOrder } from "react-data-table-component";
import { Nomination, PaginatedNominationResponse } from "../interfaces";
import { Link } from "react-router-dom";
import { getFullName } from "../helpers/user";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { getOffset } from "../helpers/pagination";
import { formatName } from "../helpers/candidate";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const PipelinePage = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [sortField, setSortField] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string | null>(null);
    const [nominations, setNominations] =
        useState<PaginatedNominationResponse | null>(null);

    const getCandidateLink = (row: Nomination) => {
        return `/candidates/${row.candidate.id}/profile`;
    };

    const getCompanyLink = (row: Nomination) => {
        return `/companies/${row.company.id}/profile`;
    };

    const getUserLink = (row: Nomination) => {
        return `/users/${row.assignedUser.id}`;
    };

    const getJobLink = (row: Nomination) => {
        return `/companies/${row.company.id}/job/${row.job.id}`;
    };

    const fetchData = async (
        offset: number,
        limit: number,
        sortField: string | null,
        sortOrder: string | null,
    ) => {
        setLoading(true);
        try {
            const request = {
                order: sortOrder,
                sort: sortField,
                offset,
                limit,
            };
            const api = await getApi();
            const response = await api.get("/pipeline", {
                params: request,
            });
            const paginationResponse: PaginatedNominationResponse =
                response.data;
            setNominations(paginationResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData(0, perPage, sortField, sortOrder);
    }, []);

    const columns: TableColumn<Nomination>[] = [
        {
            name: "",
            selector: (row: Nomination) => row.id,
            cell: (row: Nomination) => (
                <Link to={`/pipeline/${row.id}`}>Edit</Link>
            ),
        },
        {
            name: "Name",
            selector: (row: Nomination) => row.candidate.firstName,
            cell: (row: Nomination) => (
                <Link to={getCandidateLink(row)}>
                    {formatName(row.candidate)}
                </Link>
            ),
            sortable: true,
        },
        {
            name: "Company",
            selector: (row: Nomination) => row.company.name,
            cell: (row: Nomination) => (
                <Link to={getCompanyLink(row)}>{row.company.name}</Link>
            ),
            sortable: true,
        },
        {
            name: "Job",
            selector: (row: Nomination) => row.job.title,
            cell: (row: Nomination) => (
                <Link to={getJobLink(row)}>{row.job.title}</Link>
            ),
            sortable: true,
        },
        {
            name: "User",
            selector: (row: Nomination) => getFullName(row.assignedUser),
            cell: (row: Nomination) => (
                <Link to={getUserLink(row)}>
                    {getFullName(row.assignedUser)}
                </Link>
            ),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row: Nomination) => row.status,
            sortable: true,
        },
        {
            name: "Next Step Date",
            selector: (row: Nomination) => row.nextStepDate ?? "",
            sortable: true,
        },
    ];

    const handlePageChange = async (page: number) => {
        const offset = getOffset(page, perPage);
        await fetchData(offset, perPage, sortField, sortOrder);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        const offset = getOffset(page, newPerPage);
        await fetchData(offset, newPerPage, sortField, sortOrder);
    };

    const handleSort = async (
        column: TableColumn<Nomination>,
        sortDirection: SortOrder,
    ) => {
        if (column.name) {
            const newSortField = column.name.toString().toLowerCase();
            setSortField(newSortField);
            setSortOrder(sortDirection);
            fetchData(0, perPage, newSortField, sortDirection);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Pipeline</h1>
                    <DataTable
                        data={nominations?.results ?? []}
                        columns={columns}
                        progressPending={loading}
                        pagination
                        paginationServer
                        sortServer
                        paginationTotalRows={nominations?.total ?? 0}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSort={handleSort}
                    />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
