import React, { createContext, PropsWithChildren, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosError } from "axios";
import { AppContextState, UserPermissionSet } from "../interfaces";
import { handleError } from "../helpers/error";

const defaultContextState: AppContextState = {
    getApi: async () => {
        return await new Promise((done) => {
            done(axios.create());
        });
    },
    isAuthenticated: false,
    isAuthenticating: false,
    login: () => new Promise(() => null),
    logout: () => new Promise(() => null),
    user: null,
    getUser: () => new Promise(() => null),
};

export const AppContext = createContext<AppContextState>(defaultContextState);

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<UserPermissionSet | null>(null);

    const {
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    const getApi = async () => {
        const token = await getAccessTokenSilently();
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    };

    const getUserPermissions = async () => {
        if (!isAuthenticated) return;

        try {
            const api = await getApi();
            const response = await api.get("/iam/me");
            setUser(response.data as UserPermissionSet);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const value = {
        getApi,
        isAuthenticated,
        isAuthenticating: isLoading,
        login: loginWithRedirect,
        logout,
        user,
        getUser: getUserPermissions,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
