import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import DataTable, { TableColumn } from "react-data-table-component";
import { CommissionType, CommissionTypeBase } from "../interfaces";
import { AppContext } from "../context/AppContext";
import { FormTextField } from "../components/form/FormTextField";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const CommissionTypesList = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [commissionTypes, setCommissionTypes] = useState<
        CommissionType[] | null
    >(null);

    const getLink = (row: CommissionType) => {
        return `/backoffice/commission-types/${row.id}`;
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get("/accounting/commission-type");
            setCommissionTypes(response.data as CommissionType[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    // const deleteCommissionType = async (commissionType: CommissionType) => {
    //     const confirmDelete = confirm(
    //         "Are you sure you want to delete this Commission Type?",
    //     );
    //     if (confirmDelete) {
    //         setLoading(true);
    //         const api = await getApi();
    //         try {
    //             await api.delete(`/accounting/commission-type/${commissionType.id}`);
    //             const newCodes = commissionTypes?.filter(
    //                 (c) => c.id !== commissionType.id,
    //             );
    //             setCommissionTypes(newCodes as CommissionType[]);
    //         } catch (e) {
    //             handleError(e as AxiosError);
    //         }
    //         setLoading(false);
    //     }
    // };

    const columns: TableColumn<CommissionType>[] = [
        {
            name: "Name",
            selector: (row: CommissionType) => row.name,
            cell: (row: CommissionType) => (
                <Link to={getLink(row)}>{row.name}</Link>
            ),
            sortable: true,
        },
        {
            name: "Percentage",
            selector: (row: CommissionType) => `${row.percentage}%`,
            sortable: true,
        },
        // {
        //     name: "Delete",
        //     selector: () => "Delete",
        //     width: "100px",
        //     cell: (row: CommissionType) => (
        //         <a href="#" onClick={() => deleteCommissionType(row)}>
        //             Delete
        //         </a>
        //     ),
        // },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const saveCommissionType = async (
        newCommissionType: CommissionTypeBase,
    ) => {
        setLoading(true);
        try {
            const api = await getApi();
            await api.post("/accounting/commission-type", newCommissionType);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        name: yup.string().required("Name is required"),
        percentage: yup.number().required("Percentage is required"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            percentage: 0.0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: CommissionTypeBase, { resetForm }) => {
            await saveCommissionType(values);
            resetForm();
            fetchData();
        },
    });

    return (
        <>
            <h3>Commission Types</h3>
            <form onSubmit={formik.handleSubmit}>
                <FormTextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="percentage"
                    name="percentage"
                    label="Percentage"
                    type="number"
                    value={formik.values.percentage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.percentage &&
                        Boolean(formik.errors.percentage)
                    }
                    helperText={
                        formik.touched.percentage && formik.errors.percentage
                    }
                    disabled={loading}
                />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Add Commission Type
                </Button>
            </form>
            <DataTable
                data={commissionTypes ?? []}
                columns={columns}
                progressPending={loading}
                pagination
            />
        </>
    );
};
