import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    DateFormat,
    JobResponse,
    NominationRequest,
    PlacementResponse,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { FormDateField } from "../components/form/FormDateField";
import { Nomination } from "../interfaces";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";
import { formatName } from "../helpers/candidate";
import { NominationChoices } from "../helpers/nomination";
import { PlacementCreate } from "../components/PlacementCreate";

export const NominationPage = () => {
    const navigate = useNavigate();
    const { nominationId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [nomination, setNomination] = useState<Nomination | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [job, setJob] = useState<JobResponse | null>(null);

    const saveNomination = async (values: NominationRequest) => {
        try {
            const api = await getApi();
            const response = await api.put(`/pipeline/${nominationId}`, {
                status: values.status,
                active: true,
                nextStepDate: values.nextStepDate,
            });
            setNomination({
                ...nomination,
                status: response.data.status,
                nextStepDate: response.data.nextStepDate,
            } as Nomination);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const validationSchema = yup.object({
        status: yup.string().required("Status is required"),
        nextStepDate: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            status: "Submitted",
            nextStepDate: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values: NominationRequest) => {
            saveNomination(values);
        },
    });

    const fetchNomination = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get(`/pipeline/${nominationId}`);
            setNomination(response.data as Nomination);
            formik.setValues({
                status: response.data.status,
                nextStepDate: response.data.nextStepDate,
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        fetchNomination();
    }, []);

    const placedAction = (placement: PlacementResponse) => {
        navigate(`/placements/${placement.id}`);
    };

    const makePlacement = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (nomination) {
            setJob(nomination.job);
        }
    };

    const removeNomination = async () => {
        setLoading(true);
        try {
            const body = {
                nominationId: nomination?.id,
            };

            const api = await getApi();
            await api.post("/pipeline/delete", body);
            navigate("/pipeline");
            setLoading(false);
        } catch (e) {
            setLoading(false);
            handleError(e as AxiosError);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    {job && nomination && appUser ? (
                        <PlacementCreate
                            candidate={nomination.candidate}
                            clearJob={() => {
                                setJob(null);
                            }}
                            job={job}
                            placedAction={placedAction}
                            user={appUser}
                        />
                    ) : (
                        <>
                            <h1>Edit Nomination</h1>
                            <p className="bottom-margin">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={makePlacement}
                                    disabled={loading}
                                >
                                    Make Placement
                                </Button>{" "}
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={removeNomination}
                                    disabled={loading}
                                >
                                    Remove Nomination
                                </Button>
                            </p>
                            <form onSubmit={formik.handleSubmit}>
                                <FormTextField
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label="Company"
                                    type="text"
                                    value={nomination?.company.name ?? ""}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="candidate"
                                    name="candidate"
                                    label="Candidate"
                                    type="text"
                                    value={
                                        nomination?.candidate
                                            ? formatName(nomination?.candidate)
                                            : ""
                                    }
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="job"
                                    name="job"
                                    label="Job"
                                    type="text"
                                    value={nomination?.job.title ?? ""}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="user"
                                    name="user"
                                    label="User"
                                    type="text"
                                    value={
                                        nomination?.assignedUser
                                            ? formatName(
                                                  nomination?.assignedUser,
                                              )
                                            : ""
                                    }
                                    disabled
                                />
                                <FormSelectField
                                    fullWidth
                                    id="status"
                                    name="status"
                                    label="Status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.status &&
                                        Boolean(formik.errors.status)
                                    }
                                    options={NominationChoices}
                                    disabled={loading}
                                />
                                <FormDateField
                                    id="nextStepDate"
                                    label="Next Step Date"
                                    value={dayjs(formik.values.nextStepDate)}
                                    format="YYYY-MM-DD"
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            "nextStepDate",
                                            value?.format(DateFormat),
                                            true,
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={loading}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading}
                                >
                                    Save
                                </Button>
                            </form>
                        </>
                    )}
                </PaperContainer>
            </Paper>
        </Container>
    );
};
