import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { CompanyInvoiceDocumentProps } from "../../interfaces/props";
import { currencyFormatter } from "../../helpers/accounting";

const borderColor = "#E3E4E5";
const textColor = "#54585B";
const styles = StyleSheet.create({
    row: {
        color: textColor,
        flexDirection: "row",
        borderTopColor: borderColor,
        borderTopWidth: 1,
        alignItems: "center",
        height: 24,
        fontSize: 12,
        fontStyle: "bold",
    },
    blankSpace: {
        marginTop: "36px",
        width: "67%",
    },
    description: {
        fontFamily: "Helvetica-Bold",
        width: "18%",
        marginTop: "36px",
        paddingBottom: "10px",
        textAlign: "right",
        paddingRight: 8,
        borderBottomWidth: 1,
        borderBottomColor: borderColor,
    },
    total: {
        width: "15%",
        marginTop: "36px",
        paddingBottom: "10px",
        textAlign: "center",
        borderBottomWidth: 1,
        borderBottomColor: borderColor,
    },
    amountDueRow: {
        paddingTop: "16px",
    },
    removeBorder: {
        border: 0,
    },
});

const InvoiceTableFooter = (props: CompanyInvoiceDocumentProps) => {
    return (
        <>
            <View style={styles.row} wrap={false}>
                <Text style={styles.blankSpace}> </Text>
                <Text style={styles.description}>Total:</Text>
                <Text style={styles.total}>
                    {currencyFormatter(props.invoice.invoice.totalBill)}
                </Text>
            </View>
            <View
                style={{
                    ...styles.row,
                    ...styles.removeBorder,
                    ...styles.amountDueRow,
                }}
            >
                <Text style={styles.blankSpace}> </Text>
                <Text style={{ ...styles.description, ...styles.removeBorder }}>
                    Amount due:
                </Text>
                <Text style={{ ...styles.total, ...styles.removeBorder }}>
                    {currencyFormatter(props.invoice.invoice.totalBill)}
                </Text>
            </View>
        </>
    );
};

export default InvoiceTableFooter;
