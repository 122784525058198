import { useState } from "react";
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { TabPanel } from "../components/TabPanel";
import { WorkCompCodeList } from "../components/WorkCompCodeList";
import { AccountingSettingsPqage } from "./AccountingSettingsPage";
import { CommissionTypesList } from "../components/CommissionTypesList";

export const BackofficePage = () => {
    const [tabValue, setTabValue] = useState<number>(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Grid className="profile-grid" container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Paper>
                        <PaperContainer>
                            <h1>Backoffice</h1>
                            <Box>
                                <Tabs
                                    onChange={handleTabChange}
                                    value={tabValue}
                                    variant="scrollable"
                                >
                                    <Tab
                                        label="WC Codes"
                                        aria-controls="backoffice-tab-0"
                                    />
                                    <Tab
                                        label="Accounting"
                                        aria-controls="backoffice-tab-1"
                                    />
                                    <Tab
                                        label="Commission Types"
                                        aria-controls="backoffice-tab-2"
                                    />
                                </Tabs>
                            </Box>
                        </PaperContainer>
                        <PaperContainer>
                            <TabPanel
                                index={0}
                                value={tabValue}
                                aria="backoffice-tab"
                            >
                                <WorkCompCodeList />
                            </TabPanel>
                            <TabPanel
                                index={1}
                                value={tabValue}
                                aria="backoffice-tab"
                            >
                                <AccountingSettingsPqage />
                            </TabPanel>
                            <TabPanel
                                index={2}
                                value={tabValue}
                                aria="backoffice-tab"
                            >
                                <CommissionTypesList />
                            </TabPanel>
                        </PaperContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
