import { ReferenceField } from "../interfaces";

export const NominationStatus = {
    submitted: "Submitted",
    clientReviewing: "Client Reviewing",
    interviewRequest: "Interview Request",
    interviewScheduled: "Interview Scheduled",
    offerOut: "Offer Out",
    offerAccepted: "Offer Accepted",
    facilityTour: "Facility Tour",
    wotcPending: "WOTC Pending",
    sentPaperwork: "Sent Paperwork",
    paperworkCompleted: "Paperwork Completed",
    dsBgc: "DS/BGC",
    pendingStartDate: "Pending Start Date",
    start_dateScheduled: "Start Date Scheduled",
};

export const NominationChoices: ReferenceField[] = Object.values(
    NominationStatus,
).map((val) => {
    return { label: val, value: val };
});
