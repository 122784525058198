import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AppContext } from "../context/AppContext";
import { CommissionRecord, TimeTrackingPeriod } from "../interfaces";
import { PageLoader } from "./Loader";
import { currencyFormatter } from "../helpers/accounting";
import { formatNameAlphabetically } from "../helpers/candidate";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export interface UserCommissionListLoadingProps {
    userCommission: boolean;
    timeTrackingPeriod: boolean;
}

export const UserCommissionList = () => {
    const { timeTrackingPeriodId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<UserCommissionListLoadingProps>({
        userCommission: false,
        timeTrackingPeriod: false,
    });
    const [commissionRecords, setCommissionRecords] = useState<
        CommissionRecord[] | null
    >(null);
    const [timeTrackingPeriod, setTimeTrackingPeriod] =
        useState<TimeTrackingPeriod | null>(null);

    const fetchCommissionRecords = async () => {
        setLoading({ ...loading, userCommission: true });
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}/commission`,
            );
            setCommissionRecords(response.data as CommissionRecord[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading({ ...loading, userCommission: false });
    };

    const fetchTimeTrackingPeriod = async () => {
        setLoading({ ...loading, timeTrackingPeriod: true });
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}`,
            );
            setTimeTrackingPeriod(response.data as TimeTrackingPeriod);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading({ ...loading, timeTrackingPeriod: false });
    };

    useEffect(() => {
        fetchCommissionRecords();
        fetchTimeTrackingPeriod();
    }, []);

    const getUserCommissionLink = (c: CommissionRecord) => {
        return `/backoffice/time-tracking/${timeTrackingPeriodId}/user-commission/${c.user.id}`;
    };

    const isLoading = Object.values(loading).some((t) => t == true);

    return (
        <>
            <h1>User Commissions</h1>
            {timeTrackingPeriod && <h3>{timeTrackingPeriod.description}</h3>}
            {isLoading && (
                <div>
                    <PageLoader />
                </div>
            )}
            {!isLoading && (
                <TableContainer component={Paper} className="spreadsheet-table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Commission</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {commissionRecords?.map((c: CommissionRecord) => {
                                return (
                                    <TableRow key={c.id}>
                                        <TableCell align="center">
                                            <Link to={getUserCommissionLink(c)}>
                                                {formatNameAlphabetically(
                                                    c.user,
                                                )}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">
                                            {currencyFormatter(c.commission)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
