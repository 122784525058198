import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import { PaperContainer } from "../components/PaperContainer";
import { AppContext } from "../context/AppContext";
import {
    PlacementCommissionRecord,
    TimeTrackingPeriod,
    UserResponse,
} from "../interfaces";
import { PageLoader } from "../components/Loader";
import { currencyFormatter } from "../helpers/accounting";
import { formatName, formatNameAlphabetically } from "../helpers/candidate";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export interface UserCommissionListLoadingProps {
    userCommission: boolean;
    timeTrackingPeriod: boolean;
}

export const UserPlacementCommissionsPage = () => {
    const { timeTrackingPeriodId, userId } = useParams();
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<UserCommissionListLoadingProps>({
        userCommission: false,
        timeTrackingPeriod: false,
    });
    const [commissionRecords, setCommissionRecords] = useState<
        PlacementCommissionRecord[] | null
    >(null);
    const [timeTrackingPeriod, setTimeTrackingPeriod] =
        useState<TimeTrackingPeriod | null>(null);
    const csvRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);
    const [user, setUser] = useState<UserResponse | null>(null);

    const fetchUser = async () => {
        try {
            const api = await getApi();
            const response = await api.get(`/iam/user/${userId}`);
            setUser(response.data);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const fetchCommissionRecords = async () => {
        setLoading({ ...loading, userCommission: true });
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}/commission/${userId}`,
            );
            setCommissionRecords(response.data as PlacementCommissionRecord[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading({ ...loading, userCommission: false });
    };

    const fetchTimeTrackingPeriod = async () => {
        setLoading({ ...loading, timeTrackingPeriod: true });
        try {
            const api = await getApi();
            const response = await api.get(
                `/time-tracking/${timeTrackingPeriodId}`,
            );
            setTimeTrackingPeriod(response.data as TimeTrackingPeriod);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading({ ...loading, timeTrackingPeriod: false });
    };

    useEffect(() => {
        fetchCommissionRecords();
        fetchTimeTrackingPeriod();
        fetchUser();
    }, []);

    const exportReport = () => {
        if (!commissionRecords) return;
        csvRef?.current?.link.click();
    };

    const csvHeaders: string[] = [
        "Name",
        "Regular Hours",
        "OT Hours",
        "Commission",
    ];

    const formatCsvRow = (record: PlacementCommissionRecord) => {
        return [
            formatName(record.record.placement.candidate),
            record.record.hours,
            record.record.otHours,
            record.commission,
        ];
    };

    const isLoading = Object.values(loading).some((t) => t == true);

    return (
        <Grid className="profile-grid" container spacing={2}>
            <Grid item md={12} xs={12}>
                <Paper className="table-wrapper">
                    <PaperContainer>
                        <h1>User Placement Commissions</h1>
                        {timeTrackingPeriod && (
                            <h3>{timeTrackingPeriod.description} </h3>
                        )}
                        {commissionRecords && user && (
                            <p>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="button"
                                    onClick={exportReport}
                                >
                                    Export Report
                                    <CSVLink
                                        data={
                                            commissionRecords.map(
                                                formatCsvRow,
                                            ) ?? []
                                        }
                                        headers={csvHeaders}
                                        filename={`${user.lastName} ${user.firstName} - ${timeTrackingPeriod?.description}`}
                                        ref={csvRef}
                                        className="hidden"
                                    ></CSVLink>
                                </Button>
                            </p>
                        )}
                        {isLoading && (
                            <div>
                                <PageLoader />
                            </div>
                        )}
                        {!isLoading && commissionRecords?.length === 0 && (
                            <p className="text-center">
                                There are no commissions records saved for this
                                user in this time tracking period.
                            </p>
                        )}
                        {!isLoading &&
                            commissionRecords &&
                            commissionRecords.length > 0 && (
                                <TableContainer
                                    component={Paper}
                                    className="spreadsheet-table"
                                >
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    Name
                                                </TableCell>
                                                <TableCell align="center">
                                                    Regular Hours
                                                </TableCell>
                                                <TableCell align="center">
                                                    OT Hours
                                                </TableCell>
                                                <TableCell align="center">
                                                    Commission
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {commissionRecords?.map(
                                                (
                                                    c: PlacementCommissionRecord,
                                                ) => {
                                                    return (
                                                        <TableRow
                                                            key={c.record.id}
                                                        >
                                                            <TableCell align="center">
                                                                {formatNameAlphabetically(
                                                                    c.record
                                                                        .placement
                                                                        .candidate,
                                                                )}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                                {c.record.hours}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {
                                                                    c.record
                                                                        .otHours
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {currencyFormatter(
                                                                    c.commission,
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                },
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                    </PaperContainer>
                </Paper>
            </Grid>
        </Grid>
    );
};
