import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import { CompanyFormValue, CompanyResponse } from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import {
    BillingType,
    BillingTypeChoices,
    CompanyStatus,
    CompanyStatusChoices,
    PaymentTermChoices,
    PaymentTerms,
} from "../helpers/company";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { Roles, userHasRole } from "../helpers/auth";
import { YesNo, YesNoChoices } from "../helpers/job";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const CompanyPage = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();
    const { getApi, user: appUser } = useContext(AppContext);
    const [company, setCompany] = useState<CompanyResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const saveCompany = async (newCompany: CompanyFormValue) => {
        if (!company) {
            setLoading(false);
            return;
        }
        try {
            const api = await getApi();
            const response = await api.put(`/company/${companyId}`, newCompany);
            setCompany(response.data as CompanyResponse);
            navigate(`/companies/${companyId}/profile`);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const getValidationSchema = () => {
        const schema = {
            name: yup.string().required("Company name is required"),
            status: yup.string().required("Status is required"),
            website: yup.string().required("Website is required"),
            mobilePhoneNumber: yup.string().nullable(),
            phoneNumber: yup
                .string()
                .required("Office phone number is required"),
            phoneExtension: yup.string().nullable(),
            hqAddress: yup.string().required("Address is required"),
            billing: yup.string().required("Billing status is required"),
            billingAddress: yup.string().when("billing", {
                is: BillingType.companyBilling,
                then: yup.string().required("Billing address is required"),
            }),
            hasSiteLocations: yup
                .boolean()
                .required("Site locations is required"),
            paymentTerms: yup.string().required("Payment term is required"),
            poNumber: yup.string().nullable(),
        };
        return schema;
    };

    const validationSchema = yup.object(getValidationSchema());

    const formik = useFormik({
        initialValues: {
            name: "",
            status: CompanyStatus.prospect,
            website: "",
            mobilePhoneNumber: "",
            phoneNumber: "",
            phoneExtension: "",
            hqAddress: "",
            billing: BillingType.companyBilling,
            billingAddress: "",
            hasSiteLocations: YesNo.No,
            paymentTerms: PaymentTerms.dueOnReceipt,
            poNumber: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: CompanyFormValue) => {
            setLoading(true);
            saveCompany(values);
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/company/${companyId}`);
                const responseCompany: CompanyResponse =
                    response.data as CompanyResponse;
                setCompany(responseCompany);

                formik.setValues({
                    name: responseCompany.name,
                    status: responseCompany.status ?? CompanyStatus.prospect,
                    website: responseCompany.website ?? "",
                    mobilePhoneNumber: responseCompany.mobilePhoneNumber ?? "",
                    phoneNumber: responseCompany.phoneNumber ?? "",
                    phoneExtension: responseCompany.phoneExtension ?? "",
                    hqAddress: responseCompany.hqAddress ?? "",
                    billing:
                        responseCompany.billing ?? BillingType.companyBilling,
                    billingAddress: responseCompany.billingAddress ?? "",
                    hasSiteLocations: responseCompany.hasSiteLocations,
                    paymentTerms:
                        responseCompany.paymentTerms ??
                        PaymentTerms.dueOnReceipt,
                    poNumber: responseCompany.poNumber ?? "",
                });
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchData();
    }, []);

    const canEdit = () => {
        return appUser
            ? userHasRole(appUser, [Roles.admin, Roles.systemAdmin])
            : false;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>{company ? company.name : "Company"}</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {company && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    value={company?.id}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Company name"
                                    type="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.name &&
                                        Boolean(formik.errors.name)
                                    }
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="status"
                                    name="status"
                                    label="Status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.status &&
                                        Boolean(formik.errors.status)
                                    }
                                    options={CompanyStatusChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="website"
                                    name="website"
                                    label="Website"
                                    type="website"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.website &&
                                        Boolean(formik.errors.website)
                                    }
                                    helperText={
                                        formik.touched.website &&
                                        formik.errors.website
                                    }
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    label="Office phone number"
                                    type="text"
                                    value={formatPhoneNumber(
                                        formik.values.phoneNumber,
                                    )}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.phoneNumber &&
                                        Boolean(formik.errors.phoneNumber)
                                    }
                                    helperText={
                                        formik.touched.phoneNumber &&
                                        formik.errors.phoneNumber
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="phoneExtension"
                                    name="phoneExtension"
                                    label="Office phone extension"
                                    type="text"
                                    value={formatPhoneNumber(
                                        formik.values.phoneExtension,
                                    )}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.phoneExtension &&
                                        Boolean(formik.errors.phoneExtension)
                                    }
                                    helperText={
                                        formik.touched.phoneExtension &&
                                        formik.errors.phoneExtension
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="mobilePhoneNumber"
                                    name="mobilePhoneNumber"
                                    label="Mobile phone number"
                                    type="text"
                                    value={formatPhoneNumber(
                                        formik.values.mobilePhoneNumber,
                                    )}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.mobilePhoneNumber &&
                                        Boolean(formik.errors.mobilePhoneNumber)
                                    }
                                    helperText={
                                        formik.touched.mobilePhoneNumber &&
                                        formik.errors.mobilePhoneNumber
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="hqAddress"
                                    name="hqAddress"
                                    label="Address"
                                    type="hqAddress"
                                    value={formik.values.hqAddress}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.hqAddress &&
                                        Boolean(formik.errors.hqAddress)
                                    }
                                    helperText={
                                        formik.touched.hqAddress &&
                                        formik.errors.hqAddress
                                    }
                                    disabled={loading || !canEdit()}
                                    multiline
                                    rows={5}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="hasSiteLocations"
                                    name="hasSiteLocations"
                                    label="Site locations"
                                    value={formik.values.hasSiteLocations}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.hasSiteLocations &&
                                        Boolean(formik.errors.hasSiteLocations)
                                    }
                                    options={YesNoChoices}
                                    disabled={loading}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="billing"
                                    name="billing"
                                    label="Billing"
                                    value={formik.values.billing}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.billing &&
                                        Boolean(formik.errors.billing)
                                    }
                                    options={BillingTypeChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="billingAddress"
                                    name="billingAddress"
                                    label="Billing Address"
                                    type="billingAddress"
                                    value={formik.values.billingAddress}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.billingAddress &&
                                        Boolean(formik.errors.billingAddress)
                                    }
                                    helperText={
                                        formik.touched.billingAddress &&
                                        formik.errors.billingAddress
                                    }
                                    disabled={loading || !canEdit()}
                                    multiline
                                    rows={5}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="paymentTerms"
                                    name="paymentTerms"
                                    label="Payment Terms"
                                    value={formik.values.paymentTerms}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.paymentTerms &&
                                        Boolean(formik.errors.paymentTerms)
                                    }
                                    options={PaymentTermChoices}
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="poNumber"
                                    name="poNumber"
                                    label="PO Number"
                                    type="text"
                                    value={formik.values.poNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.poNumber &&
                                        Boolean(formik.errors.poNumber)
                                    }
                                    helperText={
                                        formik.touched.poNumber &&
                                        formik.errors.poNumber
                                    }
                                    disabled={loading}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading || !canEdit()}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
