import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";
import { CompanyInvoiceDocumentProps } from "../../interfaces/props";

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
    },
});

const InvoiceTable = (props: CompanyInvoiceDocumentProps) => {
    const { invoice } = props;
    return (
        <View style={styles.tableContainer}>
            <InvoiceTableHeader />
            <InvoiceTableRow records={invoice.records} />
            <InvoiceTableFooter invoice={invoice} />
        </View>
    );
};

export default InvoiceTable;
