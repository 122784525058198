import { AxiosError, AxiosInstance } from "axios";
import { handleError } from "../helpers/error";
import { CompanyInvoiceResponse } from "../interfaces";
import { CompanyInvoiceDocument } from "../components/CompanyInvoiceDocument";
import { pdf } from "@react-pdf/renderer";

export const fetchInvoice = async (
    getApi: () => Promise<AxiosInstance>,
    timeTrackingPeriodId: string,
    companyId: string,
    invoiceId: string,
) => {
    try {
        const api = await getApi();
        const response = await api.get(
            `/time-tracking/${timeTrackingPeriodId}/company/${companyId}/invoice/${invoiceId}`,
        );
        return response.data as CompanyInvoiceResponse;
    } catch (e) {
        handleError(e as AxiosError);
    }
};

export const createInvoice = async (
    getApi: () => Promise<AxiosInstance>,
    timeTrackingPeriodId: string | undefined,
    companyId: string,
    invoiceId: string,
    filename: string,
) => {
    if (!timeTrackingPeriodId) return;
    const invoice: CompanyInvoiceResponse | undefined = await fetchInvoice(
        getApi,
        timeTrackingPeriodId,
        companyId,
        invoiceId,
    );
    if (invoice) {
        const document = <CompanyInvoiceDocument invoice={invoice} />;
        const blob = await pdf(document).toBlob();
        const url = window.URL.createObjectURL(blob);
        const link = window.document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }
};
