import * as React from "react";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import BadgeIcon from "@mui/icons-material/Badge";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { AppContext } from "../context/AppContext";
import { Roles, userHasRole } from "./auth";

export const MenuListItems = () => {
    const { user } = React.useContext(AppContext);
    return (
        <React.Fragment>
            <ListItem component={Link} to="/">
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            {user && userHasRole(user, [Roles.admin, Roles.systemAdmin]) && (
                <>
                    <ListItem component={Link} to="/organizations">
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary="Organizations" />
                    </ListItem>
                    <ListItem component={Link} to="/users">
                        <ListItemIcon>
                            <BadgeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                    <ListItem component={Link} to="/backoffice">
                        <ListItemIcon>
                            <KeyboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Backoffice" />
                    </ListItem>
                    <ListItem component={Link} to="/backoffice/time-tracking">
                        <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Time Tracking" />
                    </ListItem>
                </>
            )}
            <ListItem component={Link} to="/companies">
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Companies" />
            </ListItem>
            <ListItem component={Link} to="/candidates">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Candidates" />
            </ListItem>
            <ListItem component={Link} to="/placements">
                <ListItemIcon>
                    <SettingsInputComponentIcon />
                </ListItemIcon>
                <ListItemText primary="Placements" />
            </ListItem>
            <ListItem component={Link} to="/pipeline">
                <ListItemIcon>
                    <ArrowRightAltIcon />
                </ListItemIcon>
                <ListItemText primary="Pipeline" />
            </ListItem>
        </React.Fragment>
    );
};
