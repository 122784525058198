import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export default function ErrorPage() {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <div id="error-page">
                    <h1>404</h1>
                    <p>
                        <i>{error.statusText || error.data?.message}</i>
                    </p>
                </div>
            );
        } else {
            return (
                <div id="error-page">
                    <h1>Oops!</h1>
                    <p>Sorry, an unexpected error has occurred.</p>
                    <p>
                        <i>{error.statusText || error.data?.message}</i>
                    </p>
                </div>
            );
        }
    } else {
        return <h1>404 - Not found</h1>;
    }
}
