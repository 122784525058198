import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    CommissionType,
    Organization,
    ReferenceField,
    UserFormValue,
    UserPermissionSet,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { Roles, SelectableRoles, userHasRole } from "../helpers/auth";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const UserAddPage = () => {
    const navigate = useNavigate();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<Organization[]>(
        [] as Organization[],
    );
    const [commissionTypes, setCommissionTypes] = useState<
        CommissionType[] | null
    >(null);

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/iam/organization");
                setOrganizations(response.data as Organization[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchCommissionTypes = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/accounting/commission-type");
                setCommissionTypes(response.data as CommissionType[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchOrganizations();
        fetchCommissionTypes();
    }, []);

    const saveUser = async (newUser: UserFormValue) => {
        const payload = {
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            role: newUser.role,
            commissionTypeId: newUser.commissionTypeId,
        } as UserFormValue;

        if (appUser && userHasRole(appUser, [Roles.systemAdmin])) {
            payload.organizationId = newUser.organizationId;
        }

        try {
            const api = await getApi();
            const response = await api.post("/iam/user", payload);
            if (response.data.id) {
                navigate("/users");
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const getValidationSchema = () => {
        const schema = {
            email: yup
                .string()
                .email("Enter a valid email")
                .required("Email is required"),
            firstName: yup.string().required("First name is required"),
            lastName: yup.string().required("Last name is required"),
            role: yup.string().required("Role is required"),
            organizationId: yup.string().nullable(),
            commissionTypeId: yup.string().nullable(),
        };
        if (appUser && userHasRole(appUser, [Roles.systemAdmin])) {
            schema.organizationId = yup
                .string()
                .required("Organization is required");
        }
        return schema;
    };

    const validationSchema = yup.object(getValidationSchema());

    const formik = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            role: "",
            organizationId: "",
            commissionTypeId: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values: UserFormValue) => {
            setLoading(true);
            saveUser(values);
        },
    });

    const commissionTypeChoices: ReferenceField[] = commissionTypes
        ? commissionTypes.map((commissionType: CommissionType) => {
              return {
                  label: `${commissionType.name} - ${commissionType.percentage}`,
                  value: commissionType.id,
              };
          })
        : [];
    commissionTypeChoices.unshift({ label: "None", value: null });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Create User</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {appUser && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="firstName"
                                    name="firstName"
                                    label="First name"
                                    type="text"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.firstName &&
                                        Boolean(formik.errors.firstName)
                                    }
                                    helperText={
                                        formik.touched.firstName &&
                                        formik.errors.firstName
                                    }
                                    disabled={loading}
                                />
                                <FormTextField
                                    fullWidth
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    type="text"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.lastName &&
                                        Boolean(formik.errors.lastName)
                                    }
                                    helperText={
                                        formik.touched.lastName &&
                                        formik.errors.lastName
                                    }
                                    disabled={loading}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="role"
                                    name="role"
                                    label="Role"
                                    value={
                                        userHasRole(
                                            appUser as UserPermissionSet,
                                            [Roles.systemAdmin],
                                        )
                                            ? formik.values.role
                                            : Roles.standard
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.role &&
                                        Boolean(formik.errors.role)
                                    }
                                    options={SelectableRoles}
                                    disabled={
                                        !userHasRole(
                                            appUser as UserPermissionSet,
                                            [Roles.systemAdmin],
                                        ) || loading
                                    }
                                />
                                {appUser &&
                                    userHasRole(appUser, [
                                        Roles.admin,
                                        Roles.systemAdmin,
                                    ]) && (
                                        <FormSelectField
                                            fullWidth
                                            id="commissionTypeId"
                                            name="commissionTypeId"
                                            label="Commission type"
                                            value={
                                                formik.values.commissionTypeId
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched
                                                    .commissionTypeId &&
                                                Boolean(
                                                    formik.errors
                                                        .commissionTypeId,
                                                )
                                            }
                                            options={commissionTypeChoices}
                                            disabled={loading}
                                        />
                                    )}
                                {userHasRole(appUser as UserPermissionSet, [
                                    Roles.systemAdmin,
                                ]) && (
                                    <FormSelectField
                                        fullWidth
                                        id="organizationId"
                                        name="organizationId"
                                        label="Organization"
                                        value={formik.values.organizationId}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.organizationId &&
                                            Boolean(
                                                formik.errors.organizationId,
                                            )
                                        }
                                        options={organizations.map(
                                            (org: Organization) => {
                                                return {
                                                    label: org.name,
                                                    value: org.id,
                                                };
                                            },
                                        )}
                                        disabled={loading}
                                    />
                                )}

                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
