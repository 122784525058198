import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import dayjs from "dayjs";
import { AppContext } from "../context/AppContext";
import {
    DateFormat,
    JobResponse,
    PlacementForm,
    PlacementResponse,
    PlacementTerminationForm,
    ReferenceField,
    UserResponse,
} from "../interfaces";
import { useFormik } from "formik";
import { PaperContainer } from "../components/PaperContainer";
import Button from "@mui/material/Button";
import { Container, Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormDateField } from "../components/form/FormDateField";
import { Roles, userHasRole } from "../helpers/auth";
import { JobType, getNonPermanentJobTypes } from "../helpers/job";
import { FormSelectField } from "../components/form/FormSelectField";
import { getFullName } from "../helpers/user";
import { TerminationReasonChoices } from "../helpers/placement";
import { formatName } from "../helpers/candidate";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const PlacementPage = () => {
    const { getApi, user: appUser } = useContext(AppContext);
    const { placementId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [placement, setPlacement] = useState<PlacementResponse | null>(null);
    const [users, setUsers] = useState<UserResponse[] | null>(null);
    const [showTerminate, setShowTerminate] = useState<boolean>(false);

    const fetchPlacement = async () => {
        try {
            const api = await getApi();
            const response = await api.get(`/placement/${placementId}`);
            const fetchedPlacement = response.data as PlacementResponse;
            setPlacement(fetchedPlacement);
            formik.setValues({
                ...fetchedPlacement,
                candidateId: fetchedPlacement.candidate.id,
                assignedUserId: fetchedPlacement.assignedUser.id,
            });
            terminateFormik.setValues({
                placementId: fetchedPlacement.id,
                endDate: dayjs().format(DateFormat),
                terminationReason: "",
            });
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    const fetchUsers = async () => {
        try {
            const api = await getApi();
            const response = await api.get("/iam/user");
            setUsers(response.data as UserResponse[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
    };

    useEffect(() => {
        fetchPlacement();
        fetchUsers();
    }, []);

    const savePlacement = async (newPlacement: PlacementForm) => {
        try {
            const api = await getApi();
            const response = await api.put(
                `/placement/${placementId}`,
                newPlacement,
            );
            if (response.status === 200) {
                // go back one page
                navigate(-1);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const terminatePlacement = async (
        terminatedPlacement: PlacementTerminationForm,
    ) => {
        try {
            const api = await getApi();
            const response = await api.post(
                "/placement/delete",
                terminatedPlacement,
            );
            if (response.status === 201) {
                // go back one page
                navigate(-1);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        candidateId: yup.string().required("Candidate ID is required"),
        startDate: yup.string().required("Start date is required"),
        guaranteeDate: yup.string().nullable(),
        payRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        billRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otPayRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otBillRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        directFee: yup.number().when("job.type", {
            is: JobType.permanent,
            then: yup.number().required("Direct fee is required"),
            otherwise: yup.number().nullable(),
        }),
        assignedUserId: yup.string().required("Assigned user is required"),
    });

    const terminationValidationSchema = yup.object({
        placementId: yup.string().required("Placement ID is required"),
        endDate: yup.string().required("End date is required"),
        terminationReason: yup
            .string()
            .required("Termination reason is required"),
    });

    const formik = useFormik({
        initialValues: {
            candidateId: "",
            startDate: "",
            guaranteePeriod: null as string | null,
            job: null as JobResponse | null,
            payRate: 0.0 as number | null,
            billRate: 0.0 as number | null,
            otPayRate: 0.0 as number | null,
            otBillRate: 0.0 as number | null,
            directFee: 0.0 as number | null,
            assignedUserId: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: PlacementForm) => {
            setLoading(true);
            savePlacement(values);
        },
    });

    const terminateFormik = useFormik({
        initialValues: {
            placementId: "",
            endDate: dayjs().format(DateFormat),
            terminationReason: "",
        },
        validationSchema: terminationValidationSchema,
        onSubmit: (values: PlacementTerminationForm) => {
            setLoading(true);
            terminatePlacement(values);
        },
    });

    const userChoices: ReferenceField[] = users
        ? users.map((user: UserResponse) => {
              return {
                  label: getFullName(user),
                  value: user.id,
              };
          })
        : appUser
        ? [{ label: getFullName(appUser), value: appUser.id }]
        : [];

    const canEdit = () => {
        return appUser
            ? userHasRole(appUser, [Roles.admin, Roles.systemAdmin])
            : false;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Placement</h1>
                    {placement?.active && !showTerminate && (
                        <p>
                            <Button
                                color="error"
                                variant="contained"
                                onClick={() => setShowTerminate(true)}
                            >
                                Terminate Placement
                            </Button>
                        </p>
                    )}
                    <form onSubmit={terminateFormik.handleSubmit}>
                        {placement && showTerminate && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    value={placement?.id}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="candidate"
                                    name="candidate"
                                    label="Candidate"
                                    value={
                                        placement
                                            ? formatName(placement?.candidate)
                                            : ""
                                    }
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label="Company"
                                    value={placement?.company.name}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="job"
                                    name="job"
                                    label="Job"
                                    value={placement?.job.title}
                                    disabled
                                />
                                <FormDateField
                                    id="endDate"
                                    label="End date"
                                    value={dayjs(
                                        terminateFormik.values.endDate,
                                    )}
                                    format="YYYY-MM-DD"
                                    onChange={(value) =>
                                        terminateFormik.setFieldValue(
                                            "endDate",
                                            value?.format(DateFormat),
                                            true,
                                        )
                                    }
                                    onBlur={terminateFormik.handleBlur}
                                    disabled={loading}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="terminationReason"
                                    name="terminationReason"
                                    label="Termination Reason"
                                    value={
                                        terminateFormik.values.terminationReason
                                    }
                                    onChange={terminateFormik.handleChange}
                                    onBlur={terminateFormik.handleBlur}
                                    error={
                                        terminateFormik.touched
                                            .terminationReason &&
                                        Boolean(
                                            terminateFormik.errors
                                                .terminationReason,
                                        )
                                    }
                                    options={TerminationReasonChoices}
                                    disabled={loading}
                                />
                                <Button
                                    color="error"
                                    variant="contained"
                                    type="submit"
                                    disabled={loading}
                                >
                                    Terminate Placement
                                </Button>{" "}
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => setShowTerminate(false)}
                                >
                                    Cancel
                                </Button>
                            </>
                        )}
                    </form>
                    <form onSubmit={formik.handleSubmit}>
                        {placement && !showTerminate && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    value={placement?.id}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="active"
                                    name="active"
                                    label="Active"
                                    value={placement?.active ? "True" : "False"}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="candidate"
                                    name="candidate"
                                    label="Candidate"
                                    value={
                                        placement
                                            ? formatName(placement?.candidate)
                                            : ""
                                    }
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label="Company"
                                    value={placement?.company.name}
                                    disabled
                                />
                                <FormTextField
                                    fullWidth
                                    id="job"
                                    name="job"
                                    label="Job"
                                    value={placement?.job.title}
                                    disabled
                                />
                                <FormDateField
                                    id="startDate"
                                    label="Start date"
                                    value={dayjs(formik.values.startDate)}
                                    format="YYYY-MM-DD"
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            "startDate",
                                            value?.format(DateFormat),
                                            true,
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={loading}
                                />
                                {placement.endDate &&
                                    placement.endDate != "" && (
                                        <FormDateField
                                            id="endDate"
                                            label="End date"
                                            value={dayjs(placement.endDate)}
                                            format="YYYY-MM-DD"
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    "endDate",
                                                    value?.format(DateFormat),
                                                    true,
                                                )
                                            }
                                            onBlur={formik.handleBlur}
                                            disabled={loading}
                                        />
                                    )}
                                {placement.job.type === JobType.permanent && (
                                    <FormDateField
                                        id="guaranteePeriod"
                                        label="Guarantee period"
                                        value={dayjs(
                                            formik.values.guaranteePeriod,
                                        )}
                                        format="YYYY-MM-DD"
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                "guaranteePeriod",
                                                value?.format(DateFormat),
                                                true,
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        disabled={loading}
                                    />
                                )}
                                {placement.job.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="payRate"
                                        name="payRate"
                                        label="Pay rate"
                                        type="number"
                                        value={formik.values.payRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.payRate &&
                                            Boolean(formik.errors.payRate)
                                        }
                                        helperText={
                                            formik.touched.payRate &&
                                            formik.errors.payRate
                                        }
                                        disabled={loading}
                                    />
                                )}
                                {placement.job.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="billRate"
                                        name="billRate"
                                        label="Bill rate"
                                        type="number"
                                        value={formik.values.billRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.billRate &&
                                            Boolean(formik.errors.billRate)
                                        }
                                        helperText={
                                            formik.touched.billRate &&
                                            formik.errors.billRate
                                        }
                                        disabled={loading}
                                    />
                                )}
                                {placement.job.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="otPayRate"
                                        name="otPayRate"
                                        label="OT pay rate"
                                        type="number"
                                        value={formik.values.otPayRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.otPayRate &&
                                            Boolean(formik.errors.otPayRate)
                                        }
                                        helperText={
                                            formik.touched.otPayRate &&
                                            formik.errors.otPayRate
                                        }
                                        disabled={loading}
                                    />
                                )}
                                {placement.job.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="otBillRate"
                                        name="otBillRate"
                                        label="OT bill rate"
                                        type="number"
                                        value={formik.values.otBillRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.otBillRate &&
                                            Boolean(formik.errors.otBillRate)
                                        }
                                        helperText={
                                            formik.touched.otBillRate &&
                                            formik.errors.otBillRate
                                        }
                                        disabled={loading}
                                    />
                                )}
                                {placement.job.type === JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="directFee"
                                        name="directFee"
                                        label="Direct fee"
                                        type="number"
                                        value={formik.values.directFee}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.directFee &&
                                            Boolean(formik.errors.directFee)
                                        }
                                        helperText={
                                            formik.touched.directFee &&
                                            formik.errors.directFee
                                        }
                                        disabled={loading}
                                    />
                                )}
                                {placement.terminationReason &&
                                    placement.terminationReason != "" && (
                                        <FormTextField
                                            fullWidth
                                            id="terminationReason"
                                            name="terminationReason"
                                            label="terminationReason"
                                            value={placement?.terminationReason}
                                            disabled
                                        />
                                    )}
                                <FormSelectField
                                    fullWidth
                                    id="assignedUserId"
                                    name="assignedUserId"
                                    label="User"
                                    value={formik.values.assignedUserId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.assignedUserId &&
                                        Boolean(formik.errors.assignedUserId)
                                    }
                                    options={userChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading}
                                >
                                    Save Placement
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
