import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { CompanyResponse } from "../interfaces";
import { ContactsList } from "../components/ContactsList";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const ContactsPage = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [company, setCompany] = useState<CompanyResponse | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/company/${companyId}`);
                setCompany(response.data as CompanyResponse);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchData();
    }, []);

    return <ContactsList company={company} user={appUser} />;
};
