import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { CompanySiteLocationResponse } from "../interfaces";
import { Button } from "@mui/material";
import { SiteLocationsListProps } from "../interfaces/props";
import { Roles, userHasRole } from "../helpers/auth";

export const SiteLocationsList = (props: SiteLocationsListProps) => {
    const { company, user } = props;

    const getLink = (row: CompanySiteLocationResponse) => {
        return `/companies/${company?.id}/site-locations/${row.id}`;
    };

    const columns: TableColumn<CompanySiteLocationResponse>[] = [
        {
            name: "Name",
            selector: (row: CompanySiteLocationResponse) => row.name,
            cell: (row: CompanySiteLocationResponse) => (
                <Link to={getLink(row)}>{row.name}</Link>
            ),
        },
        {
            name: "Address",
            selector: (row: CompanySiteLocationResponse) => row.address,
            cell: (row: CompanySiteLocationResponse) => (
                <Link to={getLink(row)}>{row.address}</Link>
            ),
        },
    ];

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <h1>
                {company && `${company?.name ?? "Company"}: `}Site Locations
            </h1>
            <p>
                {company &&
                    user &&
                    userHasRole(user, [Roles.admin, Roles.systemAdmin]) && (
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={`/companies/${company?.id}/site-locations/add`}
                        >
                            Add Site Location
                        </Button>
                    )}
            </p>
            <DataTable
                data={
                    (company?.siteLocations ??
                        []) as CompanySiteLocationResponse[]
                }
                columns={columns}
                pagination
            />
        </Container>
    );
};
