import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { PlacementCreateProps } from "../interfaces/props";
import {
    DateFormat,
    JobResponse,
    PlacementAddForm,
    PlacementResponse,
} from "../interfaces";
import { useContext, useState } from "react";
import { FormDateField } from "./form/FormDateField";
import { JobType, getNonPermanentJobTypes } from "../helpers/job";
import { Button } from "@mui/material";
import { FormTextField } from "./form/FormTextField";
import { AppContext } from "../context/AppContext";
import { formatName } from "../helpers/candidate";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const PlacementCreate = (props: PlacementCreateProps) => {
    const { getApi } = useContext(AppContext);
    const { candidate, clearJob, job, placedAction, user } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const savePlacement = async (newPlacement: PlacementAddForm) => {
        try {
            const api = await getApi();
            const response = await api.post("/placement", newPlacement);
            if (response.status === 201 && placedAction) {
                placedAction(response.data as PlacementResponse);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        candidateId: yup.string().required("Candidate ID is required"),
        assignedUserId: yup.string().required("Assigned User is required"),
        startDate: yup.string().required("Start date is required"),
        guaranteePeriod: yup.string().when("job.type", {
            is: JobType.permanent,
            then: yup.string().required("Guarantee period is required"),
            otherwise: yup.string().nullable(),
        }),
        payRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        billRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otPayRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otBillRate: yup.number().when("job.type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        directFee: yup.number().when("job.type", {
            is: JobType.permanent,
            then: yup.number().required("Direct fee is required"),
            otherwise: yup.number().nullable(),
        }),
        companyId: yup.string().required("Company ID is required"),
        jobId: yup.string().required("Job ID is required"),
    });

    const formik = useFormik({
        initialValues: {
            candidateId: candidate.id,
            assignedUserId: user.id,
            startDate: job.startDate,
            guaranteePeriod: null,
            job: job as JobResponse | null,
            payRate: job.payRate,
            billRate: job.billRate,
            otPayRate: job.otPayRate,
            otBillRate: job.otBillRate,
            directFee: job.directFee,
            companyId: job.company.id,
            jobId: job.id,
        },
        validationSchema: validationSchema,
        onSubmit: (values: PlacementAddForm) => {
            setLoading(true);
            savePlacement(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <FormTextField
                fullWidth
                id="candidate"
                name="candidate"
                label="Candidate"
                type="text"
                value={formatName(candidate)}
            />
            <FormTextField
                fullWidth
                id="job"
                name="job"
                label="Job"
                type="text"
                value={job.title}
            />
            <FormDateField
                id="startDate"
                label="Start date"
                value={dayjs(formik.values.startDate)}
                format="YYYY-MM-DD"
                onChange={(value) =>
                    formik.setFieldValue(
                        "startDate",
                        value?.format(DateFormat),
                        true,
                    )
                }
                onBlur={formik.handleBlur}
                disabled={loading}
            />
            {job.type === JobType.permanent && (
                <FormDateField
                    id="guaranteePeriod"
                    label="Guarantee period"
                    value={dayjs(formik.values.guaranteePeriod)}
                    format="YYYY-MM-DD"
                    onChange={(value) =>
                        formik.setFieldValue(
                            "guaranteePeriod",
                            value?.format(DateFormat),
                            true,
                        )
                    }
                    onBlur={formik.handleBlur}
                    disabled={loading}
                />
            )}
            {job.type !== JobType.permanent && (
                <FormTextField
                    fullWidth
                    id="payRate"
                    name="payRate"
                    label="Pay rate"
                    type="number"
                    value={formik.values.payRate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.payRate && Boolean(formik.errors.payRate)
                    }
                    helperText={formik.touched.payRate && formik.errors.payRate}
                    disabled={loading}
                />
            )}
            {job.type !== JobType.permanent && (
                <FormTextField
                    fullWidth
                    id="billRate"
                    name="billRate"
                    label="Bill rate"
                    type="number"
                    value={formik.values.billRate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.billRate &&
                        Boolean(formik.errors.billRate)
                    }
                    helperText={
                        formik.touched.billRate && formik.errors.billRate
                    }
                    disabled={loading}
                />
            )}
            {job.type !== JobType.permanent && (
                <FormTextField
                    fullWidth
                    id="otPayRate"
                    name="otPayRate"
                    label="OT pay rate"
                    type="number"
                    value={formik.values.otPayRate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.otPayRate &&
                        Boolean(formik.errors.otPayRate)
                    }
                    helperText={
                        formik.touched.otPayRate && formik.errors.otPayRate
                    }
                    disabled={loading}
                />
            )}
            {job.type !== JobType.permanent && (
                <FormTextField
                    fullWidth
                    id="otBillRate"
                    name="otBillRate"
                    label="OT bill rate"
                    type="number"
                    value={formik.values.otBillRate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.otBillRate &&
                        Boolean(formik.errors.otBillRate)
                    }
                    helperText={
                        formik.touched.otBillRate && formik.errors.otBillRate
                    }
                    disabled={loading}
                />
            )}
            {job.type === JobType.permanent && (
                <FormTextField
                    fullWidth
                    id="directFee"
                    name="directFee"
                    label="Direct fee"
                    type="number"
                    value={formik.values.directFee}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.directFee &&
                        Boolean(formik.errors.directFee)
                    }
                    helperText={
                        formik.touched.directFee && formik.errors.directFee
                    }
                    disabled={loading}
                />
            )}
            <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading || !formik.touched || !formik.isValid}
            >
                Make Placement
            </Button>{" "}
            <Button
                color="error"
                variant="outlined"
                disabled={loading}
                onClick={() => clearJob()}
            >
                Back
            </Button>
        </form>
    );
};
