import TextField, { TextFieldProps } from "@mui/material/TextField";

export const FormTextField = (props: TextFieldProps) => {
    const componentClass = props.className
        ? `form-field ${props.className}`
        : "form-field";
    return (
        <div className={componentClass}>
            <TextField {...props} />
        </div>
    );
};
