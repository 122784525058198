import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    JobAddForm,
    CompanySiteLocationResponse,
    UserResponse,
    ReferenceField,
    DateFormat,
    WorkCompCode,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { FormDateField } from "../components/form/FormDateField";
import {
    JobStatus,
    JobType,
    YesNo,
    JobStatusChoices,
    JobTypeChoices,
    YesNoChoices,
    LevelChoices,
    getNonPermanentJobTypes,
} from "../helpers/job";
import dayjs from "dayjs";
import { getFullName } from "../helpers/user";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const JobAddPage = () => {
    const { companyId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [siteLocations, setSiteLocations] = useState<
        CompanySiteLocationResponse[] | null
    >(null);
    const [users, setUsers] = useState<UserResponse[] | null>(null);
    const [workCompCodes, setWorkCompCodes] = useState<WorkCompCode[] | null>(
        null,
    );

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSiteLocations = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/company/${companyId}/site-location`,
                );
                setSiteLocations(
                    response.data as CompanySiteLocationResponse[],
                );
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchUsers = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/iam/user");
                setUsers(response.data as UserResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchWorkCompCodes = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/comp-code");
                setWorkCompCodes(response.data as WorkCompCode[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };

        fetchUsers();
        fetchSiteLocations();
        fetchWorkCompCodes();
    }, []);

    const saveJob = async (newJob: JobAddForm) => {
        if (!appUser) {
            setLoading(false);
            return;
        }

        try {
            const api = await getApi();
            const response = await api.post("/job", newJob);
            if (response.status === 201) {
                navigate(`/companies/${companyId}/profile`);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        status: yup.string().required("Status is required"),
        title: yup.string().required("Title is required"),
        recurring: yup.bool().required("Recurring is required"),
        type: yup.string().required("Job type is required"),
        level: yup.string().required("Level is required"),
        startDate: yup.date().required("Start date is required"),
        endDate: yup.date().nullable(),
        perpetual: yup.bool().required("Perpetual is required"),
        payRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        billRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otPayRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otBillRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        directFee: yup.number().when("type", {
            is: JobType.permanent,
            then: yup.number().required("Direct fee is required"),
            otherwise: yup.number().nullable(),
        }),
        drugScreen: yup.bool().required(),
        backgroundCheck: yup.bool().required(),
        workCompCode: yup.string().nullable(),
        workCompCodeId: yup.string().nullable(),
        description: yup.string().required("Description is required"),
        userId: yup.string().nullable(),
        siteLocationId: yup.string().nullable(),
        companyId: yup.string().required("Company is required"),
    });

    const formik = useFormik({
        initialValues: {
            status: JobStatus.open,
            title: "",
            recurring: YesNo.No,
            type: JobType.contract,
            level: 1,
            startDate: dayjs().format(DateFormat),
            endDate: null,
            perpetual: YesNo.No,
            payRate: 0.0,
            billRate: 0.0,
            otPayRate: 0.0,
            otBillRate: 0.0,
            directFee: 0.0,
            drugScreen: YesNo.Yes,
            backgroundCheck: YesNo.Yes,
            workCompCode: "",
            workCompCodeId: undefined as string | undefined,
            description: "",
            userId: appUser ? appUser?.id : null,
            companyId: companyId as string,
            siteLocationId: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: (values: JobAddForm) => {
            setLoading(true);
            saveJob(values);
        },
    });

    const siteLocationChoices: ReferenceField[] = siteLocations
        ? siteLocations.map((siteLocation: CompanySiteLocationResponse) => {
              return {
                  label: siteLocation.name,
                  value: siteLocation.id,
              };
          })
        : [];
    siteLocationChoices.unshift({ label: "None", value: undefined });

    const userChoices: ReferenceField[] = users
        ? users.map((user: UserResponse) => {
              return {
                  label: getFullName(user),
                  value: user.id,
              };
          })
        : appUser
        ? [{ label: getFullName(appUser), value: appUser.id }]
        : [];

    const workCompCodeOptions: ReferenceField[] = workCompCodes
        ? workCompCodes.map((workCompCode: WorkCompCode) => {
              return {
                  label: `${workCompCode.location} - ${workCompCode.code}`,
                  value: workCompCode.id,
              };
          })
        : [];
    workCompCodeOptions.unshift({ label: "None", value: undefined });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Add Job</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <FormSelectField
                                fullWidth
                                id="status"
                                name="status"
                                label="Status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.status &&
                                    Boolean(formik.errors.status)
                                }
                                options={JobStatusChoices}
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="title"
                                name="title"
                                label="Title"
                                type="text"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.title &&
                                    Boolean(formik.errors.title)
                                }
                                helperText={
                                    formik.touched.title && formik.errors.title
                                }
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="recurring"
                                name="recurring"
                                label="Recurring"
                                value={formik.values.recurring}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.recurring &&
                                    Boolean(formik.errors.recurring)
                                }
                                options={YesNoChoices}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="type"
                                name="type"
                                label="Type"
                                value={formik.values.type}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.type &&
                                    Boolean(formik.errors.type)
                                }
                                options={JobTypeChoices}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="level"
                                name="level"
                                label="Level"
                                value={formik.values.level}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.level &&
                                    Boolean(formik.errors.level)
                                }
                                options={LevelChoices}
                                disabled={loading}
                            />
                            <FormDateField
                                id="startDate"
                                label="Start date"
                                value={dayjs(formik.values.startDate)}
                                format="YYYY-MM-DD"
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        "startDate",
                                        value?.format(DateFormat),
                                        true,
                                    )
                                }
                                onBlur={formik.handleBlur}
                                disabled={loading}
                            />
                            <FormDateField
                                id="endDate"
                                label="End date"
                                value={dayjs(formik.values.endDate)}
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        "endDate",
                                        value?.format(DateFormat),
                                        true,
                                    )
                                }
                                onBlur={formik.handleBlur}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="perpetual"
                                name="perpetual"
                                label="Perpetual"
                                value={formik.values.perpetual}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.perpetual &&
                                    Boolean(formik.errors.perpetual)
                                }
                                options={YesNoChoices}
                                disabled={loading}
                            />
                            {formik.values.type !== JobType.permanent && (
                                <FormTextField
                                    fullWidth
                                    id="payRate"
                                    name="payRate"
                                    label="Pay rate"
                                    type="number"
                                    value={formik.values.payRate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.payRate &&
                                        Boolean(formik.errors.payRate)
                                    }
                                    helperText={
                                        formik.touched.payRate &&
                                        formik.errors.payRate
                                    }
                                    disabled={loading}
                                />
                            )}
                            {formik.values.type !== JobType.permanent && (
                                <FormTextField
                                    fullWidth
                                    id="billRate"
                                    name="billRate"
                                    label="Bill rate"
                                    type="number"
                                    value={formik.values.billRate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.billRate &&
                                        Boolean(formik.errors.billRate)
                                    }
                                    helperText={
                                        formik.touched.billRate &&
                                        formik.errors.billRate
                                    }
                                    disabled={loading}
                                />
                            )}
                            {formik.values.type !== JobType.permanent && (
                                <FormTextField
                                    fullWidth
                                    id="otPayRate"
                                    name="otPayRate"
                                    label="OT pay rate"
                                    type="number"
                                    value={formik.values.otPayRate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.otPayRate &&
                                        Boolean(formik.errors.otPayRate)
                                    }
                                    helperText={
                                        formik.touched.otPayRate &&
                                        formik.errors.otPayRate
                                    }
                                    disabled={loading}
                                />
                            )}
                            {formik.values.type !== JobType.permanent && (
                                <FormTextField
                                    fullWidth
                                    id="otBillRate"
                                    name="otBillRate"
                                    label="OT bill rate"
                                    type="number"
                                    value={formik.values.otBillRate}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.otBillRate &&
                                        Boolean(formik.errors.otBillRate)
                                    }
                                    helperText={
                                        formik.touched.otBillRate &&
                                        formik.errors.otBillRate
                                    }
                                    disabled={loading}
                                />
                            )}
                            {formik.values.type === JobType.permanent && (
                                <FormTextField
                                    fullWidth
                                    id="directFee"
                                    name="directFee"
                                    label="Direct fee"
                                    type="number"
                                    value={formik.values.directFee}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.directFee &&
                                        Boolean(formik.errors.directFee)
                                    }
                                    helperText={
                                        formik.touched.directFee &&
                                        formik.errors.directFee
                                    }
                                    disabled={loading}
                                />
                            )}
                            <FormSelectField
                                fullWidth
                                id="drugScreen"
                                name="drugScreen"
                                label="Drug screen"
                                value={formik.values.drugScreen}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.drugScreen &&
                                    Boolean(formik.errors.drugScreen)
                                }
                                options={YesNoChoices}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="backgroundCheck"
                                name="backgroundCheck"
                                label="Background check"
                                value={formik.values.backgroundCheck}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.backgroundCheck &&
                                    Boolean(formik.errors.backgroundCheck)
                                }
                                options={YesNoChoices}
                                disabled={loading}
                            />

                            <FormSelectField
                                fullWidth
                                id="workCompCodeId"
                                name="workCompCodeId"
                                label="Work comp code"
                                value={formik.values.workCompCodeId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.workCompCodeId &&
                                    Boolean(formik.errors.workCompCodeId)
                                }
                                options={workCompCodeOptions}
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="description"
                                name="description"
                                label="Description"
                                type="text"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.description &&
                                    Boolean(formik.errors.description)
                                }
                                helperText={
                                    formik.touched.description &&
                                    formik.errors.description
                                }
                                disabled={loading}
                                multiline
                                rows={5}
                            />
                            <FormSelectField
                                fullWidth
                                id="siteLocationId"
                                name="siteLocationId"
                                label="Site Location"
                                value={formik.values.siteLocationId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.siteLocationId &&
                                    Boolean(formik.errors.siteLocationId)
                                }
                                options={siteLocationChoices}
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="userId"
                                name="userId"
                                label="User"
                                value={formik.values.userId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.userId &&
                                    Boolean(formik.errors.userId)
                                }
                                options={userChoices}
                                disabled={loading}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Add Job
                            </Button>
                        </>
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
