import { FormEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import { Candidate, PaginatedCandidateResponse } from "../interfaces";
import { Button, Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { getOffset } from "../helpers/pagination";
import { formatName } from "../helpers/candidate";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";
import { FormTextField } from "../components/form/FormTextField";

export const CandidatesPage = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(10);
    const [candidates, setCandidates] =
        useState<PaginatedCandidateResponse | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const getLink = (row: Candidate) => {
        return `/candidates/${row.id}/profile`;
    };

    const columns: TableColumn<Candidate>[] = [
        {
            name: "Name",
            selector: (row: Candidate) => row.firstName,
            cell: (row: Candidate) => (
                <Link to={getLink(row)}>{formatName(row)}</Link>
            ),
        },
        {
            name: "Status",
            selector: (row: Candidate) => row.status,
        },
        {
            name: "Email",
            selector: (row: Candidate) => row.email,
        },
        {
            name: "Phone number",
            selector: (row: Candidate) => formatPhoneNumber(row.phoneNumber),
        },
        {
            name: "City",
            selector: (row: Candidate) => row.city,
        },
        {
            name: "State",
            selector: (row: Candidate) => row.state,
        },
        {
            name: "Zip code",
            selector: (row: Candidate) => row.zipCode,
        },
    ];

    const fetchData = async (offset: number, limit: number) => {
        setLoading(true);
        try {
            const params =
                searchQuery.length > 0
                    ? { offset, limit, query: searchQuery }
                    : { offset, limit };
            const api = await getApi();
            const response = await api.get("/candidate", {
                params: params,
            });
            const paginationResponse: PaginatedCandidateResponse =
                response.data;
            setCandidates(paginationResponse);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData(0, perPage);
    }, []);

    const handlePageChange = async (page: number) => {
        const offset = getOffset(page, perPage);
        await fetchData(offset, perPage);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        const offset = getOffset(page, newPerPage);
        await fetchData(offset, newPerPage);
    };

    const submitSearch = (e: FormEvent) => {
        e.preventDefault();
        fetchData(0, perPage);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>
                        Candidates
                        <form className="float-right" onSubmit={submitSearch}>
                            <FormTextField
                                id="searchQuery"
                                name="searchQuery"
                                label="Candidate search"
                                type="text"
                                className="inline"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </form>
                    </h1>
                    <p>
                        <Button
                            color="primary"
                            variant="contained"
                            component={Link}
                            to={"/candidates/add"}
                        >
                            Add Candidate
                        </Button>
                    </p>
                    <DataTable
                        data={candidates?.results ?? []}
                        columns={columns}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={candidates?.total ?? 0}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
