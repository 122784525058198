import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import { CandidateForm, ReferenceField, UserResponse } from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { StateChoices } from "../helpers/location";
import { StatusChoices } from "../helpers/candidate";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const CandidateAddPage = () => {
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<ReferenceField[]>(
        [] as ReferenceField[],
    );
    const navigate = useNavigate();

    const saveCandidate = async (newCandidate: CandidateForm) => {
        const payload = {
            ...newCandidate,
            userId: newCandidate.user,
            phoneNumber: newCandidate.phoneNumber.replace(/\D+/g, ""),
        };

        try {
            const api = await getApi();
            const response = await api.post("/candidate", payload);
            if (response.status === 201) {
                navigate("/candidates");
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        firstName: yup.string().required("First name is required"),
        lastName: yup.string().required("Last name is required"),
        suffix: yup.string().nullable(),
        email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is required"),
        phoneNumber: yup.string().required("Phone number is required"),
        phoneExtension: yup.string().nullable(),
        address: yup.string().required("Address is required"),
        addressUnitNumber: yup.string().nullable(),
        city: yup.string().required("City is required"),
        state: yup.string().required("State is required"),
        zipCode: yup.string().required("Zip code is required"),
        status: yup.string(),
        fileId: yup.string().nullable(),
        user: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            suffix: "",
            phoneNumber: "",
            address: "",
            addressUnitNumber: "",
            city: "",
            state: "",
            zipCode: "",
            status: "Active",
            fileId: "",
            user: appUser?.id ?? "",
        },
        validationSchema: validationSchema,
        onSubmit: (values: CandidateForm) => {
            setLoading(true);
            saveCandidate(values);
        },
    });

    const getFullName = (user: UserResponse): string => {
        return user.firstName + " " + user.lastName;
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/iam/organization/${appUser?.organization?.id}/users`,
                );
                const responseUsers: UserResponse[] =
                    response.data as UserResponse[];
                setUsers(
                    responseUsers.map((user) => {
                        return {
                            label: getFullName(user),
                            value: user.id,
                        };
                    }),
                );
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchUsers();
    }, [appUser]);

    const formatPhoneNumber = (value: string) => {
        const ps = parsePhoneNumber(value, { regionCode: "US" });
        return ps?.number?.national ?? value;
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Add Candidate</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <>
                            <FormTextField
                                fullWidth
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.email &&
                                    Boolean(formik.errors.email)
                                }
                                helperText={
                                    formik.touched.email && formik.errors.email
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="firstName"
                                name="firstName"
                                label="First name"
                                type="text"
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.firstName &&
                                    Boolean(formik.errors.firstName)
                                }
                                helperText={
                                    formik.touched.firstName &&
                                    formik.errors.firstName
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                type="text"
                                value={formik.values.lastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.lastName &&
                                    Boolean(formik.errors.lastName)
                                }
                                helperText={
                                    formik.touched.lastName &&
                                    formik.errors.lastName
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="suffix"
                                name="suffix"
                                label="Suffix"
                                type="text"
                                value={formik.values.suffix}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.suffix &&
                                    Boolean(formik.errors.suffix)
                                }
                                helperText={
                                    formik.touched.suffix &&
                                    formik.errors.suffix
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone number"
                                type="text"
                                value={formatPhoneNumber(
                                    formik.values.phoneNumber,
                                )}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.phoneNumber &&
                                    Boolean(formik.errors.phoneNumber)
                                }
                                helperText={
                                    formik.touched.phoneNumber &&
                                    formik.errors.phoneNumber
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="address"
                                name="address"
                                label="Address"
                                type="text"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                }
                                helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="addressUnitNumber"
                                name="addressUnitNumber"
                                label="Address unit number"
                                type="text"
                                value={formik.values.addressUnitNumber ?? ""}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.addressUnitNumber &&
                                    Boolean(formik.errors.addressUnitNumber)
                                }
                                helperText={
                                    formik.touched.addressUnitNumber &&
                                    formik.errors.addressUnitNumber
                                }
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="city"
                                name="city"
                                label="City"
                                type="text"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.city &&
                                    Boolean(formik.errors.city)
                                }
                                helperText={
                                    formik.touched.city && formik.errors.city
                                }
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="state"
                                name="state"
                                label="State"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.state &&
                                    Boolean(formik.errors.state)
                                }
                                options={StateChoices}
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="zipCode"
                                name="zipCode"
                                label="Zip code"
                                type="text"
                                value={formik.values.zipCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.zipCode &&
                                    Boolean(formik.errors.zipCode)
                                }
                                helperText={
                                    formik.touched.zipCode &&
                                    formik.errors.zipCode
                                }
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="status"
                                name="status"
                                label="Status"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.status &&
                                    Boolean(formik.errors.status)
                                }
                                options={StatusChoices}
                                disabled={loading}
                            />
                            <FormTextField
                                fullWidth
                                id="fileId"
                                name="fileId"
                                label="File ID"
                                type="text"
                                value={formik.values.fileId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.fileId &&
                                    Boolean(formik.errors.fileId)
                                }
                                helperText={
                                    formik.touched.fileId &&
                                    formik.errors.fileId
                                }
                                disabled={loading}
                            />
                            <FormSelectField
                                fullWidth
                                id="user"
                                name="user"
                                label="User"
                                value={formik.values.user}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                    formik.touched.user &&
                                    Boolean(formik.errors.user)
                                }
                                options={users}
                                disabled={loading}
                            />
                            <Button
                                color="primary"
                                variant="contained"
                                fullWidth
                                type="submit"
                                disabled={loading}
                            >
                                Save Candidate
                            </Button>
                        </>
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
