import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import { Organization } from "../interfaces";
import { Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const OrganizationsPage = () => {
    const { getApi } = useContext(AppContext);
    const [organizations, setOrganizations] = useState<Organization[]>(
        [] as Organization[],
    );

    const getLink = (row: Organization) => {
        return `/organizations/${row.id}`;
    };

    const columns: TableColumn<Organization>[] = [
        {
            name: "ID",
            selector: (row: Organization) => row.id,
            cell: (row: Organization) => (
                <Link to={getLink(row)}>{row.id}</Link>
            ),
        },
        {
            name: "Organization Name",
            selector: (row: Organization) => row.name,
            cell: (row: Organization) => (
                <Link to={getLink(row)}>{row.name}</Link>
            ),
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/iam/organization");
                setOrganizations(response.data as Organization[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchData();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>Organizations</h1>
                    <DataTable data={organizations} columns={columns} />
                </PaperContainer>
            </Paper>
        </Container>
    );
};
