import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { AppContext } from "../context/AppContext";
import { Organization, UserPermissionSet } from "../interfaces";
import { Paper } from "@mui/material";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const OrganizationPage = () => {
    const { organizationId } = useParams();
    const { getApi } = useContext(AppContext);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [users, setUsers] = useState<UserPermissionSet[]>(
        [] as UserPermissionSet[],
    );

    useEffect(() => {
        const fetchOrganization = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/iam/organization/${organizationId}`,
                );
                const org = response.data as Organization;
                setOrganization(org);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchUsers = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/iam/organization/${organizationId}/users`,
                );
                setUsers(response.data as UserPermissionSet[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        fetchUsers();
        fetchOrganization();
    }, []);

    const getLink = (user: UserPermissionSet) => {
        return `/users/${user.id}`;
    };

    const columns: TableColumn<UserPermissionSet>[] = [
        {
            name: "ID",
            selector: (row: UserPermissionSet) => row.id,
            cell: (row: UserPermissionSet) => (
                <Link to={getLink(row)}>{row.id}</Link>
            ),
        },
        {
            name: "Name",
            selector: (row: UserPermissionSet) => row.firstName,
            cell: (row: UserPermissionSet) => (
                <Link to={getLink(row)}>
                    {row.firstName + " " + row.lastName}
                </Link>
            ),
        },
        {
            name: "Role",
            selector: (row: UserPermissionSet) => row.permissions.role,
        },
    ];

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>{organization ? organization.name : "Organization"}</h1>
                    {organization && (
                        <>
                            <p>
                                <b>ID:</b> {organization.id}
                            </p>
                            <p>
                                <b>Frontoffice:</b>{" "}
                                {organization.permissions?.hasFrontoffice.toString()}
                            </p>
                            <p>
                                <b>Backoffice:</b>{" "}
                                {organization.permissions?.hasBackoffice.toString()}
                            </p>
                        </>
                    )}
                    {users && (
                        <>
                            <h2>Users</h2>
                            <DataTable data={users} columns={columns} />
                        </>
                    )}
                </PaperContainer>
            </Paper>
        </Container>
    );
};
