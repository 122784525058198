import { ReferenceField } from "../interfaces";

export const AccountingPeriods = {
    mondayToSunday: "Monday to Sunday",
    sundayToSaturday: "Sunday to Saturday",
};

export const AccountingPeriodChoices: ReferenceField[] = Object.values(
    AccountingPeriods,
).map((s: string) => ({ label: s, value: s }));

export const currencyFormatter = (num: number): string => {
    return `$${num.toFixed(2)}`;
};
