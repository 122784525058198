import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { AppContext } from "../context/AppContext";
import {
    JobForm,
    CompanySiteLocationResponse,
    UserResponse,
    ReferenceField,
    DateFormat,
    JobResponse,
    WorkCompCode,
} from "../interfaces";
import { Paper } from "@mui/material";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { FormDateField } from "../components/form/FormDateField";
import {
    JobStatus,
    JobType,
    YesNo,
    JobStatusChoices,
    JobTypeChoices,
    YesNoChoices,
    LevelChoices,
    getNonPermanentJobTypes,
} from "../helpers/job";
import dayjs from "dayjs";
import { getFullName } from "../helpers/user";
import { Roles, userHasRole } from "../helpers/auth";
import { PaperContainer } from "../components/PaperContainer";
import { handleError } from "../helpers/error";
import { AxiosError } from "axios";

export const JobPage = () => {
    const { companyId, jobId } = useParams();
    const { getApi, user: appUser } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [siteLocations, setSiteLocations] = useState<
        CompanySiteLocationResponse[] | null
    >(null);
    const [users, setUsers] = useState<UserResponse[] | null>(null);
    const [job, setJob] = useState<JobResponse | null>(null);
    const [workCompCodes, setWorkCompCodes] = useState<WorkCompCode[] | null>(
        null,
    );

    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const api = await getApi();
                const response = await api.get(`/job/${jobId}`);
                const jobResponse: JobResponse = response.data as JobResponse;
                setJob(jobResponse);
                formik.setValues({
                    ...jobResponse,
                    userId: jobResponse.user?.id ?? "",
                    siteLocationId: jobResponse.siteLocation?.id ?? undefined,
                    workCompCodeId: jobResponse.wcCode?.id ?? undefined,
                });
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchSiteLocations = async () => {
            try {
                const api = await getApi();
                const response = await api.get(
                    `/company/${companyId}/site-location`,
                );
                setSiteLocations(
                    response.data as CompanySiteLocationResponse[],
                );
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchWorkCompCodes = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/comp-code");
                setWorkCompCodes(response.data as WorkCompCode[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };
        const fetchUsers = async () => {
            try {
                const api = await getApi();
                const response = await api.get("/iam/user");
                setUsers(response.data as UserResponse[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
        };

        fetchJobs();
        fetchUsers();
        fetchSiteLocations();
        fetchWorkCompCodes();
    }, []);

    const saveJob = async (newJob: JobForm) => {
        if (!appUser) {
            setLoading(false);
            return;
        }

        try {
            const api = await getApi();
            const response = await api.put(`/job/${jobId}`, newJob);
            if (response.status === 200) {
                navigate(`/companies/${companyId}/profile`);
            }
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        status: yup.string().required("Status is required"),
        title: yup.string().required("Title is required"),
        recurring: yup.bool().required("Recurring is required"),
        type: yup.string().required("Job type is required"),
        level: yup.string().required("Level is required"),
        startDate: yup.date().required("Start date is required"),
        endDate: yup.date().nullable(),
        perpetual: yup.bool().required("Perpetual is required"),
        payRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        billRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("Bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otPayRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT pay rate is required"),
            otherwise: yup.number().nullable(),
        }),
        otBillRate: yup.number().when("type", {
            is: getNonPermanentJobTypes(),
            then: yup.number().required("OT bill rate is required"),
            otherwise: yup.number().nullable(),
        }),
        directFee: yup.number().when("type", {
            is: JobType.permanent,
            then: yup.number().required("Direct fee is required"),
            otherwise: yup.number().nullable(),
        }),
        drugScreen: yup.bool().required(),
        backgroundCheck: yup.bool().required(),
        workCompCode: yup.string().nullable(),
        workCompCodeId: yup.string().nullable(),
        description: yup.string().required("Description is required"),
        userId: yup.string().nullable(),
        siteLocationId: yup.string().nullable(),
    });

    const formik = useFormik({
        initialValues: {
            status: JobStatus.open,
            title: "",
            recurring: YesNo.No,
            type: JobType.contract,
            level: 1,
            startDate: dayjs().format(DateFormat),
            endDate: null as string | null,
            perpetual: YesNo.No,
            payRate: 0.0 as number | null,
            billRate: 0.0 as number | null,
            otPayRate: 0.0 as number | null,
            otBillRate: 0.0 as number | null,
            directFee: 0.0 as number | null,
            drugScreen: YesNo.Yes,
            backgroundCheck: YesNo.Yes,
            workCompCode: "",
            workCompCodeId: undefined as string | undefined,
            description: "",
            userId: appUser ? appUser?.id : null,
            siteLocationId: undefined as string | undefined,
        },
        validationSchema: validationSchema,
        onSubmit: (values: JobForm) => {
            setLoading(true);
            saveJob(values);
        },
    });

    const siteLocationChoices: ReferenceField[] = siteLocations
        ? siteLocations.map((siteLocation: CompanySiteLocationResponse) => {
              return {
                  label: siteLocation.name,
                  value: siteLocation.id,
              };
          })
        : [];
    siteLocationChoices.unshift({ label: "None", value: undefined });

    const userChoices: ReferenceField[] = users
        ? users.map((user: UserResponse) => {
              return {
                  label: getFullName(user),
                  value: user.id,
              };
          })
        : appUser
        ? [{ label: getFullName(appUser), value: appUser.id }]
        : [];

    const canEdit = () => {
        return appUser
            ? userHasRole(appUser, [Roles.admin, Roles.systemAdmin])
            : false;
    };

    const workCompCodeOptions: ReferenceField[] = workCompCodes
        ? workCompCodes.map((workCompCode: WorkCompCode) => {
              return {
                  label: `${workCompCode.location} - ${workCompCode.code}`,
                  value: workCompCode.id,
              };
          })
        : [];
    workCompCodeOptions.unshift({ label: "None", value: undefined });

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper>
                <PaperContainer>
                    <h1>{job?.title ?? "Job"}</h1>
                    <form onSubmit={formik.handleSubmit}>
                        {job && (
                            <>
                                <FormTextField
                                    fullWidth
                                    id="id"
                                    name="id"
                                    label="ID"
                                    type="text"
                                    value={job.id}
                                    disabled={true}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="status"
                                    name="status"
                                    label="Status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.status &&
                                        Boolean(formik.errors.status)
                                    }
                                    options={JobStatusChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="title"
                                    name="title"
                                    label="Title"
                                    type="text"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.title &&
                                        Boolean(formik.errors.title)
                                    }
                                    helperText={
                                        formik.touched.title &&
                                        formik.errors.title
                                    }
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="recurring"
                                    name="recurring"
                                    label="Recurring"
                                    value={formik.values.recurring}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.recurring &&
                                        Boolean(formik.errors.recurring)
                                    }
                                    options={YesNoChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="type"
                                    name="type"
                                    label="Type"
                                    value={formik.values.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.type &&
                                        Boolean(formik.errors.type)
                                    }
                                    options={JobTypeChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="level"
                                    name="level"
                                    label="Level"
                                    value={formik.values.level}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.level &&
                                        Boolean(formik.errors.level)
                                    }
                                    options={LevelChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormDateField
                                    id="startDate"
                                    label="Start date"
                                    value={dayjs(formik.values.startDate)}
                                    format="YYYY-MM-DD"
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            "startDate",
                                            value?.format(DateFormat),
                                            true,
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={loading || !canEdit()}
                                />
                                <FormDateField
                                    id="endDate"
                                    label="End date"
                                    value={dayjs(formik.values.endDate)}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            "endDate",
                                            value?.format(DateFormat),
                                            true,
                                        )
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="perpetual"
                                    name="perpetual"
                                    label="Perpetual"
                                    value={formik.values.perpetual}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.perpetual &&
                                        Boolean(formik.errors.perpetual)
                                    }
                                    options={YesNoChoices}
                                    disabled={loading || !canEdit()}
                                />
                                {formik.values.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="payRate"
                                        name="payRate"
                                        label="Pay rate"
                                        type="number"
                                        value={formik.values.payRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.payRate &&
                                            Boolean(formik.errors.payRate)
                                        }
                                        helperText={
                                            formik.touched.payRate &&
                                            formik.errors.payRate
                                        }
                                        disabled={loading || !canEdit()}
                                    />
                                )}
                                {formik.values.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="billRate"
                                        name="billRate"
                                        label="Bill rate"
                                        type="number"
                                        value={formik.values.billRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.billRate &&
                                            Boolean(formik.errors.billRate)
                                        }
                                        helperText={
                                            formik.touched.billRate &&
                                            formik.errors.billRate
                                        }
                                        disabled={loading || !canEdit()}
                                    />
                                )}
                                {formik.values.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="otPayRate"
                                        name="otPayRate"
                                        label="OT pay rate"
                                        type="number"
                                        value={formik.values.otPayRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.otPayRate &&
                                            Boolean(formik.errors.otPayRate)
                                        }
                                        helperText={
                                            formik.touched.otPayRate &&
                                            formik.errors.otPayRate
                                        }
                                        disabled={loading || !canEdit()}
                                    />
                                )}
                                {formik.values.type !== JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="otBillRate"
                                        name="otBillRate"
                                        label="OT bill rate"
                                        type="number"
                                        value={formik.values.otBillRate}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.otBillRate &&
                                            Boolean(formik.errors.otBillRate)
                                        }
                                        helperText={
                                            formik.touched.otBillRate &&
                                            formik.errors.otBillRate
                                        }
                                        disabled={loading || !canEdit()}
                                    />
                                )}
                                {formik.values.type === JobType.permanent && (
                                    <FormTextField
                                        fullWidth
                                        id="directFee"
                                        name="directFee"
                                        label="Direct fee"
                                        type="number"
                                        value={formik.values.directFee}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.directFee &&
                                            Boolean(formik.errors.directFee)
                                        }
                                        helperText={
                                            formik.touched.directFee &&
                                            formik.errors.directFee
                                        }
                                        disabled={loading}
                                    />
                                )}
                                <FormSelectField
                                    fullWidth
                                    id="drugScreen"
                                    name="drugScreen"
                                    label="Drug screen"
                                    value={formik.values.drugScreen}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.drugScreen &&
                                        Boolean(formik.errors.drugScreen)
                                    }
                                    options={YesNoChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="backgroundCheck"
                                    name="backgroundCheck"
                                    label="Background check"
                                    value={formik.values.backgroundCheck}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.backgroundCheck &&
                                        Boolean(formik.errors.backgroundCheck)
                                    }
                                    options={YesNoChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="workCompCode"
                                    name="workCompCode"
                                    label="Work comp code (DEPRECATED)"
                                    type="text"
                                    value={formik.values.workCompCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.workCompCode &&
                                        Boolean(formik.errors.workCompCode)
                                    }
                                    helperText={
                                        formik.touched.workCompCode &&
                                        formik.errors.workCompCode
                                    }
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="workCompCodeId"
                                    name="workCompCodeId"
                                    label="Work comp code"
                                    value={formik.values.workCompCodeId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.workCompCodeId &&
                                        Boolean(formik.errors.workCompCodeId)
                                    }
                                    options={workCompCodeOptions}
                                    disabled={loading || !canEdit()}
                                />
                                <FormTextField
                                    fullWidth
                                    id="description"
                                    name="description"
                                    label="Description"
                                    type="text"
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.description &&
                                        Boolean(formik.errors.description)
                                    }
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                    disabled={loading || !canEdit()}
                                    multiline
                                    rows={5}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="siteLocationId"
                                    name="siteLocationId"
                                    label="Site Location"
                                    value={formik.values.siteLocationId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.siteLocationId &&
                                        Boolean(formik.errors.siteLocationId)
                                    }
                                    options={siteLocationChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <FormSelectField
                                    fullWidth
                                    id="userId"
                                    name="userId"
                                    label="User"
                                    value={formik.values.userId}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.userId &&
                                        Boolean(formik.errors.userId)
                                    }
                                    options={userChoices}
                                    disabled={loading || !canEdit()}
                                />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    disabled={loading || !canEdit()}
                                >
                                    Save Job
                                </Button>
                            </>
                        )}
                    </form>
                </PaperContainer>
            </Paper>
        </Container>
    );
};
