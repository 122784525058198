import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import DataTable, { TableColumn } from "react-data-table-component";
import { WorkCompCode, WorkCompCodeBase } from "../interfaces";
import { formatPhoneNumber } from "../helpers/phoneNumber";
import { AppContext } from "../context/AppContext";
import { FormTextField } from "../components/form/FormTextField";
import { FormSelectField } from "../components/form/FormSelectField";
import { StateChoices } from "../helpers/location";
import { AxiosError } from "axios";
import { handleError } from "../helpers/error";

export const WorkCompCodeList = () => {
    const { getApi } = useContext(AppContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [workCompCodes, setWorkCompCodes] = useState<WorkCompCode[] | null>(
        null,
    );

    const getLink = (row: WorkCompCode) => {
        return `/backoffice/worker-comp-codes/${row.id}`;
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const api = await getApi();
            const response = await api.get("/comp-code");
            setWorkCompCodes(response.data as WorkCompCode[]);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const deleteWorkCompCode = async (compCode: WorkCompCode) => {
        const confirmDelete = confirm(
            "Are you sure you want to delete this Worker's Comp Code?",
        );
        if (confirmDelete) {
            setLoading(true);
            const api = await getApi();
            try {
                await api.delete(`/comp-code/${compCode.id}`);
                const newCodes = workCompCodes?.filter(
                    (c) => c.id !== compCode.id,
                );
                setWorkCompCodes(newCodes as WorkCompCode[]);
            } catch (e) {
                handleError(e as AxiosError);
            }
            setLoading(false);
        }
    };

    const columns: TableColumn<WorkCompCode>[] = [
        {
            name: "Code",
            selector: (row: WorkCompCode) => row.code,
            width: "140px",
            cell: (row: WorkCompCode) => (
                <Link to={getLink(row)}>{row.code}</Link>
            ),
            sortable: true,
        },
        {
            name: "Location",
            selector: (row: WorkCompCode) => row.location,
            width: "100px",
            sortable: true,
        },
        {
            name: "Percentage",
            selector: (row: WorkCompCode) => `${row.percentage}%`,
            width: "120px",
            sortable: true,
        },
        {
            name: "Description",
            selector: (row: WorkCompCode) =>
                formatPhoneNumber(row.description ?? ""),
            sortable: true,
        },
        {
            name: "Delete",
            selector: () => "Delete",
            width: "100px",
            cell: (row: WorkCompCode) => (
                <a href="#" onClick={() => deleteWorkCompCode(row)}>
                    Delete
                </a>
            ),
        },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    const saveWorkCompCode = async (newWorkCompCode: WorkCompCodeBase) => {
        setLoading(true);
        try {
            const api = await getApi();
            await api.post("/comp-code", newWorkCompCode);
        } catch (e) {
            handleError(e as AxiosError);
        }
        setLoading(false);
    };

    const validationSchema = yup.object({
        code: yup.string().required("Code is required"),
        location: yup.string().required("Location is required"),
        description: yup.string().required("Description is required"),
        percentage: yup.number().required("Percentage is required"),
    });

    const formik = useFormik({
        initialValues: {
            code: "",
            location: "",
            description: "",
            percentage: 0.0,
        },
        validationSchema: validationSchema,
        onSubmit: async (values: WorkCompCodeBase, { resetForm }) => {
            await saveWorkCompCode(values);
            resetForm();
            fetchData();
        },
    });

    return (
        <>
            <h3>Worker Comp Codes</h3>
            <form onSubmit={formik.handleSubmit}>
                <FormTextField
                    fullWidth
                    id="code"
                    name="code"
                    label="Code"
                    type="text"
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code && Boolean(formik.errors.code)}
                    helperText={formik.touched.code && formik.errors.code}
                    disabled={loading}
                />
                <FormSelectField
                    fullWidth
                    id="location"
                    name="location"
                    label="Location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                    }
                    options={StateChoices}
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    type="text"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                    }
                    helperText={
                        formik.touched.description && formik.errors.description
                    }
                    disabled={loading}
                />
                <FormTextField
                    fullWidth
                    id="percentage"
                    name="percentage"
                    label="Percentage ($100 of payroll as percentage)"
                    type="number"
                    value={formik.values.percentage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                        formik.touched.percentage &&
                        Boolean(formik.errors.percentage)
                    }
                    helperText={
                        formik.touched.percentage && formik.errors.percentage
                    }
                    disabled={loading}
                />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                >
                    Add Worker Comp Code
                </Button>
            </form>
            <DataTable
                data={workCompCodes ?? []}
                columns={columns}
                progressPending={loading}
                pagination
            />
        </>
    );
};
