import {
    Candidate,
    CandidateForm,
    ReferenceField,
    UserResponse,
} from "../interfaces";

export const CandidateStatus = {
    active: "Active",
    placed: "Placed",
    dnu: "DNU",
    restricted: "Restricted",
};

export const StatusChoices: ReferenceField[] = Object.values(
    CandidateStatus,
).map((s: string) => ({ label: s, value: s }));

export const formatName = (
    candidate: Candidate | CandidateForm | UserResponse,
) => {
    const baseName = `${candidate.firstName} ${candidate.lastName}`;
    if (
        "suffix" in candidate &&
        candidate.suffix &&
        candidate.suffix.trim() != ""
    ) {
        return `${baseName} ${candidate.suffix}`;
    } else {
        return baseName;
    }
};

export const formatNameAlphabetically = (
    candidate: Candidate | CandidateForm | UserResponse,
) => {
    const baseName = `${candidate.lastName}, ${candidate.firstName}`;
    if (
        "suffix" in candidate &&
        candidate.suffix &&
        candidate.suffix.trim() != ""
    ) {
        return `${baseName} ${candidate.suffix}`;
    } else {
        return baseName;
    }
};
