import dayjs from "dayjs";
import { ReferenceField } from "../interfaces";

export const JobStatus = {
    open: "Open",
    close: "Closed",
};

export const JobType = {
    contract: "Contract",
    contract_to_hire: "Contract to Hire",
    permanent: "Permanent",
};

export const YesNo = {
    Yes: true,
    No: false,
};

export const Levels = {
    one: 1,
    two: 2,
    three: 3,
};

export const getNonPermanentJobTypes = () => {
    return Object.values(JobType).filter((t) => t !== JobType.permanent);
};

export const JobStatusChoices: ReferenceField[] = Object.values(JobStatus).map(
    (o) => ({
        label: o,
        value: o,
    }),
);

export const JobTypeChoices: ReferenceField[] = Object.values(JobType).map(
    (o) => ({
        label: o,
        value: o,
    }),
);

export const LevelChoices: ReferenceField[] = Object.entries(Levels).map(
    (o) => ({
        label: o[1].toString(),
        value: o[1],
    }),
);

export const YesNoChoices: ReferenceField[] = Object.entries(YesNo).map(
    (entry) => {
        return {
            label: entry[0],
            value: entry[1],
        };
    },
);

export const getJobDate = (date: Date): Date => {
    return new Date(dayjs(date).format("YYYY-MM-DD"));
};
