import { useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import Container from "@mui/material/Container";
import DataTable, { TableColumn } from "react-data-table-component";
import { DateFormat, JobResponse } from "../interfaces";
import { CandidateJobsListProps } from "../interfaces/props";
import { Button, CircularProgress } from "@mui/material";

export const CandidateJobsList = (props: CandidateJobsListProps) => {
    const { fetchJobs, jobs, loading, actionType, selectJob } = props;

    const getLink = (row: JobResponse) => {
        return `/companies/${row.company.id}/job/${row.id}`;
    };

    const getCompanyLink = (row: JobResponse) => {
        return `/companies/${row.company.id}/profile`;
    };

    const columns: TableColumn<JobResponse>[] = [
        {
            name: "",
            selector: (row: JobResponse) => row.title,
            cell: (row: JobResponse) => (
                <Button
                    color="primary"
                    variant="outlined"
                    className="nowrap"
                    onClick={() => selectJob(row)}
                >
                    {actionType}
                </Button>
            ),
        },
        {
            name: "Job",
            selector: (row: JobResponse) => row.title,
            cell: (row: JobResponse) => (
                <Link to={getLink(row)}>{row.title}</Link>
            ),
        },
        {
            name: "Company",
            selector: (row: JobResponse) => row.company.name,
            cell: (row: JobResponse) => (
                <Link to={getCompanyLink(row)}>{row.company.name}</Link>
            ),
        },
        {
            name: "Status",
            selector: (row: JobResponse) => row.status,
        },
        {
            name: "Start date",
            selector: (row: JobResponse) =>
                dayjs(row.startDate).format(DateFormat),
        },
        {
            name: "End date",
            selector: (row: JobResponse) =>
                row.endDate
                    ? dayjs(row.endDate).format(DateFormat)
                    : row.perpetual
                    ? "Perpetual"
                    : "",
        },
        {
            name: "Pay rate",
            selector: (row: JobResponse) =>
                row.payRate ? `$${row.payRate.toFixed(2)}` : "N/A",
        },
        {
            name: "Site location",
            selector: (row: JobResponse) => row.siteLocation?.name ?? "HQ",
        },
    ];

    useEffect(() => {
        fetchJobs();
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {loading && <CircularProgress />}
            {!loading && <DataTable data={jobs} columns={columns} pagination />}
        </Container>
    );
};
